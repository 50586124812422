import React, { useEffect, useRef, useState } from "react";
import { Card, CardContent, Grid, Skeleton } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import HighchartContainer from "../../../../components/HighchartContainer/HighchartContainer";
import { chartConfig } from "../../chartConfig";
import { NoData } from "../../../../styles/Common.Styled";
import Dashboard from "../../../../components/Dashboard/Dashboard";
import { getReports } from "../../service";
import { messages } from "../../../../util/config";
import { useDispatch, useSelector } from "react-redux";
import { loader } from "../../../../store/actions/common.action";
import { IReportingChartSerie } from "../../../../mocks/reportingMock";
const Report: React.FC<{
	seriesData: IReportingChartSerie;
	filters: { country: string[]; date: string; periodicity: string; users: string[] } | undefined;
	index: number;
}> = ({ seriesData, filters, index }) => {
	const chartRef = useRef(null);
	const [skeleton, setSkeleton] = useState(true);
	const [chartTableData, setChartTableData] = useState({ ...seriesData });
	const dispatch = useDispatch();
	const sidebarOpen = useSelector((state: any) => state.common.sidebarOpen);

	useEffect(() => {
		if (filters && filters.country && filters.country.length > 0) {
			setSkeleton(true);
			dispatch(loader(true));
			getReports(seriesData.queryFunction, filters)
				.then((res) => {
					if (res.status === 200 && res.data) {
						let newSeriesData = { ...seriesData };

						//chart data
						newSeriesData.data = res.data.chartData.map((item) => {
							return item.value;
						});
						newSeriesData.categories = res.data.chartData.map((item) => {
							return item.date;
						});
						//chart data end

						//table data
						if (res.data.tableData && res.data.tableData.length > 0 && newSeriesData.tableData.show) {
							let headers: any = [{ title: "Dashboard Name" }, { title: "Total Count" }];
							let allValues: any = [];
							res.data.tableData.map((item: any, i) => {
								let values: any = [];
								if (i === 0) {
									item.data.forEach((record) => headers.push({ title: record.label }));
								}
								values.push(item.dashboardName);
								values.push(item.totalCount);

								item.data.forEach((record) => values.push(record.value));
								allValues.push(values);
							});
							newSeriesData.tableData.headers = headers;
							newSeriesData.tableData.values = allValues;
						}
						//table data end
						setChartTableData(newSeriesData);
						setSkeleton(false);
						dispatch(loader(false));
					} else {
						let newSeriesData = { ...chartTableData };
						newSeriesData.tableData.headers = [];
						newSeriesData.tableData.values = [];
						newSeriesData.data = [];
						newSeriesData.categories = [];
						setChartTableData(newSeriesData);
						setSkeleton(false);
						dispatch(loader(false));
					}
				})
				.catch((e) => {
					let tempChartTableData = { ...chartTableData };
					tempChartTableData.tableData.values = [];
					tempChartTableData.tableData.headers = [];
					tempChartTableData.data = [];
					tempChartTableData.categories = [];
					setChartTableData({ ...tempChartTableData });
					setSkeleton(false);
					dispatch(loader(false));
				});
		}
	}, [filters]);

	return (
		<Grid container>
			<Grid md={12} className="m-t-20">
				<Card>
					<CardContent>
						<Dashboard
							title={chartTableData.name}
							tooltip={chartTableData.name}
							id={`usage-count-report-chart-${index}`}
							chartRef={chartRef}
							showSkeleton={skeleton}
						>
							{skeleton ? (
								<>
									<Grid>
										<Skeleton variant="rectangular" height={300} />
									</Grid>
									<Grid className="m-t-20">
										<CustomTable
											heading={[1, 2, 3, 4, 5, 6]}
											value={[
												[1, 2, 3, 4, 5, 6],
												[1, 2, 3, 4, 5, 6],
												[1, 2, 3, 4, 5, 6],
											]}
											showSkeleton={skeleton}
										/>
									</Grid>
								</>
							) : chartTableData?.data.length > 0 ? (
								<Grid container>
									<Grid item md={12}>
										<HighchartContainer chartRef={chartRef} id={`usage-count-report-chart-${index}`}>
											<HighchartsReact highcharts={Highcharts} options={chartConfig(chartTableData)} ref={chartRef} />
										</HighchartContainer>
									</Grid>
									<Grid
										item
										md={12}
										style={{
											marginTop: 20,
											maxWidth: sidebarOpen ? "83.175vw" : "91.875vw",
										}}
									>
										{chartTableData && (
											<CustomTable
												heading={[...chartTableData.tableData.headers]}
												value={[...chartTableData.tableData.values]}
												stickyHeader={true}
												maxHeight={400}
												isFormatNumber={false}
											/>
										)}
									</Grid>
								</Grid>
							) : (
								<Grid container display="flex" justifyContent="center" alignItems="center" style={{ height: 400 }}>
									<NoData sx={{ color: "black" }}>{messages.reports}</NoData>
								</Grid>
							)}
						</Dashboard>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

export default Report;
