import { IStats } from "../types/common";
import { DateTime } from "luxon";
import { NetRevenue } from "../assets/icons/dashboard";
import { dataFormat } from "../util/helper";
import { faCoins, faScaleUnbalancedFlip, faChartPie, faCartShopping } from "@fortawesome/free-solid-svg-icons";

const KPIData1: Array<IStats> = [
	{
		icon: faCoins,
		color: "#008cca",
		title: "Sales",
		value: "0",
		raise: null,
		change: "0%",
	},
	{
		icon: faScaleUnbalancedFlip,
		color: "#00a84d",
		title: "VOLUME (KGS)",
		value: "0",
		raise: null,
		change: "0%",
	},
	{
		icon: faCartShopping,
		color: "#ff6910",
		title: "UNITS",
		value: "0",
		raise: null,
		change: "0%",
	},
	{
		icon: NetRevenue,
		title: "Gross Profit",
		value: "0",
		raise: null,
		change: "0%",
		className: "row1NetRevenue",
	},
];

const KPIData2: Array<IStats> = [
	{
		icon: faChartPie,
		color: "#008cca",
		title: "SHARE OF MARKET - SALES",
		value: "0",
		raise: null,
		change: "0%",
	},
	{
		icon: faChartPie,
		color: "#00a84d",
		title: "SHARE OF MARKET - VOLUME",
		value: "0",
		raise: null,
		change: "0%",
	},
	{
		icon: faChartPie,
		color: "#ff6910",
		title: "SHARE OF MARKET - UNITS",
		value: "0",
		raise: null,
		change: "0%",
	},
	{
		icon: NetRevenue,
		title: "Gross Profit",
		value: "0",
		raise: null,
		change: "0%",
		className: "row2NetRevenue",
	},
];

const sortingDropdown = [
	{ label: "Year To Date", value: "YearToDate" },
	{ label: "Last 12 Months", value: "Last12Months" },
	{ label: "Quarter To Date", value: "QuarterToDate" },
	{
		label: dataFormat(DateTime.local().minus({ quarter: 1 }).toISO(), "Qq yyyy"),
		value: "Q-1",
	},
	{
		label: dataFormat(DateTime.local().minus({ quarter: 2 }).toISO(), "Qq yyyy"),
		value: "Q-2",
	},
	{ label: "Month To Date", value: "MonthToDate" },
	{ label: "Last Month", value: "LastMonth" },
	{ label: "Last Week", value: "LastWeek" },
];

const dataObject = {
	KPIData1,
	KPIData2,
	sortingDropdown,
};

export default dataObject;
