import { IAssortmentEditScenarioPayload, IAssortmentListPayload } from "../../../types/mixAndAssortment";

/* Creates a GraphQL query to get list of scenarios created for assortment page.
 * @param {IAssortmentEditScenarioPayload} payload - The payload data.
 * @returns The GraphQL query.
 */
export const EDIT_SCENARIO_QUERY = (payload: IAssortmentEditScenarioPayload) => {
	return {
		query: `mutation {
    editAssortmentRunSimulation(
        scenarioName: "${payload.scenarioName}"
        createdBy: "${payload.createdBy}"
        createdDate: "${new Date(Date.now())}"
        userInputs: "${payload.userInputs}"
        isNewScenario: "${payload.isNewScenario ? "Y" : "N"}"
    ) {
        success
        message
    }
}`,
	};
};

/* Creates a GraphQL query to get the details of a scenario created for assortment page.
 * @param {string} dashboardName - The page name.
 * @returns The GraphQL query.
 */
export const FETCH_SCENARIO_DETAILS_QUERY = (scenarioName: string) => {
	return {
		query: `query {
    getAssortmentScenarioDetails(scenarioName: "${scenarioName}", dashboardName: "Assortment") {
        scenarioName
        userInputs
        assortmentGeoFilters {
            channel
            region
            storeSegment
        }
        message
    }
}`,
	};
};

/* Creates a GraphQL query to get the assortment current average data in assortment page.
 * @param {IAssortmentListPayload} payload - The payload data.
 * @returns The GraphQL query.
 */
export const FETCH_ASSORTMENT_AVG_DATA_QUERY = (payload: IAssortmentListPayload) => {
	return {
		query: `query {
    getAssortmentCurrentAverage(
      scenarioName: "${payload.scenarioName}",
      channel : "${payload.channel}",
      region: "${payload.region}",
      storeSegment: "${payload.storeSegment}"
    ) {
        goodToHaveSkuCurrentAverage
        mustHaveSkuCurrentAverage
        goodToHaveSKU
        mustHaveSKU
    }
}`,
	};
};

/* Creates a GraphQL query to get the full data of a scenario created for assortment page.
 * @param {string} scenarioName - The scenario name.
 * @returns The GraphQL query.
 */
export const FETCH_FULL_ASSORTMENT_SIMULATIONDATA_QUERY = (scenarioName: string) => {
	return {
		query: `query {
    getDownloadAssortmentSimulationData(scenarioName: "${scenarioName}") {
        success
        message
        data
    }
}`,
	};
};

/* Creates a GraphQL query to get the full data for assortment list table in assortment page.
 * @param {IAssortmentListPayload} payload - The payload data.
 * @returns The GraphQL query.
 */
export const FETCH_ASSORTMENT_LIST_DATA_QUERY = (payload: IAssortmentListPayload) => {
	return {
		query: `query {
    getDownloadAssortmenrListTable(scenarioName: "${payload.scenarioName}",
        goodToHaveSKU: ${payload.goodToHaveSKU} 
        mustHaveSKU: ${payload.mustHaveSKU}
        channel: "${payload.channel}"
        region: "${payload.region}"
        storeSegment: "${payload.storeSegment}") {
        success
        message
        data
    }
}`,
	};
};
