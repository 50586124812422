import _ from "lodash";
import { DateTime } from "luxon";
import $ from "jquery";
import { toast } from "../store/actions/toast.action";
import { loader } from "../store/actions/common.action";
import { IDefaultFiltersAPIPayload, SimulationDetailsBrand } from "../types/common";
import permissions from "./roleConfig.json";
import { OktaAuth } from "@okta/okta-auth-js";
import axios from "axios";
import { updateChartFonts } from "../components/MMPW/MMPW";
import pako from "pako";
import { fromByteArray } from "base64-js";
import { IExecutionGeoSummaryData, IMixPortfolioAndGeoData } from "../types/mixAndAssortment";

const oktaAuthConfig = async () => {
	const res = await fetch("/env/env.json").then((res) => res.json());
	return new OktaAuth({
		issuer: res.AUTH_URL,
		clientId: res.IDENTITY_CLIENT_ID,
		redirectUri: window.location.origin + "/signin-oidc",
		scopes: ["openid", "email", "profile", "offline_access"],
	});
};

export const uniqueColors = [
	"#00FF00",
	"#0000FF",
	"#FF0000",
	"#01FFFE",
	"#FFA6FE",
	"#FFDB66",
	"#006401",
	"#010067",
	"#29d682",
	"#95003A",
	"#007DB5",
	"#9b7b5c",
	"#56f565",
	"#FF00F6",
	"#774D00",
	"#90FB92",
	"#0076FF",
	"#D5FF00",
	"#FF937E",
	"#6A826C",
	"#FF029D",
	"#FE8900",
	"#7A4782",
	"#7E2DD2",
	"#85A900",
	"#FF0056",
	"#4b7c52",
	"#A42400",
	"#5ee5f0",
	"#00AE7E",
	"#683D3B",
	"#c5e344",
	"#BDC6FF",
	"#263400",
	"#BDD393",
	"#00B917",
	"#9E008E",
	"#001544",
	"#C28C9F",
	"#FF74A3",
	"#01D0FF",
	"#004754",
	"#E56FFE",
	"#788231",
	"#0E4CA1",
	"#91D0CB",
	"#BE9970",
	"#968AE8",
	"#BB8800",
	"#43002C",
	"#DEFF74",
	"#00FFC6",
	"#FFE502",
	"#620E00",
	"#008F9C",
	"#98FF52",
	"#7544B1",
	"#0e4c6b",
	"#B500FF",
	"#00FF78",
	"#FF6E41",
	"#005F39",
	"#6B6882",
	"#5FAD4E",
	"#A75740",
	"#e22b7e",
	"#A5FFD2",
	"#FFB167",
	"#009BFF",
	"#E85EBE",
];

const symbolArr = ["e", "E", "+", "-"];
export const inputRegex = (event) => {
	return symbolArr.includes(event.key) && event.preventDefault();
};

export const setCorrelationColor = (val: number): { bgcolor: string; color: string; padding: string } => {
	if (val < 0) {
		// to make it positive
		val = val * -1;
	}
	if (val < 50) {
		return {
			bgcolor: "red",
			color: "#fff",
			padding: "8px",
		};
	} else if (val >= 50 && val <= 70) {
		return {
			bgcolor: "#f5f524",
			color: "#000",
			padding: "8px",
		};
	} else
		return {
			bgcolor: "green",
			color: "#fff",
			padding: "8px",
		};
};

export const setRSquareColor = (val: number): { bgcolor: string; color: string } => {
	if (val < 5) {
		return {
			bgcolor: "red",
			color: "#fff",
		};
	} else if (val >= 5 && val <= 17) {
		return {
			bgcolor: "#f5f524",
			color: "#000",
		};
	} else {
		return {
			bgcolor: "green",
			color: "#fff",
		};
	}
};

export const setSimulationTableColor = (val): { color: string; bgcolor: null } => {
	return {
		color: val > 0 ? "green" : "#F00",
		bgcolor: null,
	};
};

export const dataFormat = (data, format) => {
	if (_.isArray(data)) {
		return data.map((item) => DateTime.fromISO(item).toFormat(format));
	} else if (!_.isString(data)) {
		return DateTime.fromJSDate(data).toFormat(format);
	} else {
		return DateTime.fromJSDate(new Date(data)).toFormat(format);
	}
};

export const API = axios.create({
	headers: { "Content-Type": "application/json" },
});

API.interceptors.request.use(
	async function (config) {
		const oktaAuthObject: OktaAuth = await oktaAuthConfig();
		config.headers.Authorization = `Bearer ${oktaAuthObject.getAccessToken()}`;
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

API.interceptors.response.use(
	function (response) {
		return response;
	},
	async function (error) {
		console.log(error.status);
		const oktaAuthObject: OktaAuth = await oktaAuthConfig();
		oktaAuthObject.tokenManager
			.renew("accessToken")
			.then(function (session) {
				console.log("success", session);
			})
			.catch(function (err) {
				console.log("failed", err);
			});
		return Promise.reject(error);
	}
);

export const mapFilterOptions = (response, data) => {
	if (response && response.data) {
		Object.keys(response.data).map((key) => {
			if (response.data[key] && data[key]) {
				data[key].options = response.data[key];
			}
		});
	}

	return data;
};

export const disabledFiltersWithVendor = (payload, isPepsico) => {
	let data = {
		vendor: isPepsico,
		category: true,
		segment: true,
		brand: true,
		subBrand: true,
		packSize: true,
	};
	const key = Object.keys(payload)[0];
	let val = payload[key];
	if (_.isArray(val) && val.length === 0) {
		val = null;
	}

	switch (key) {
		case "vendor":
			data = {
				...data,
				vendor: isPepsico,
				category: val === null,
			};
			break;
		case "category":
			data = {
				...data,
				vendor: isPepsico,
				category: false,
				segment: val === null,
			};
			break;
		case "segment":
			data = {
				...data,
				vendor: isPepsico,
				category: false,
				segment: false,
				brand: val === null,
			};
			break;
		case "brand":
			data = {
				...data,
				vendor: isPepsico,
				category: false,
				segment: false,
				brand: false,
				subBrand: val === null,
			};
			break;
		case "subBrand":
			data = {
				...data,
				vendor: isPepsico,
				category: false,
				segment: false,
				brand: false,
				subBrand: false,
				packSize: val === null,
			};
			break;
		case "packSize":
			data = {
				...data,
				vendor: isPepsico,
				category: false,
				segment: false,
				brand: false,
				subBrand: false,
				packSize: false,
			};
			break;
	}
	return data;
};
export const formatDefaultFilters = (keys, defaultFilters) => {
	let defaultFilterAPIPayload: IDefaultFiltersAPIPayload[] = [];
	keys.forEach((key) => {
		if (defaultFilters[key]?.length > 0) {
			defaultFilterAPIPayload.push({
				name: key,
				value: defaultFilters[key],
				apiCalled: false,
				key: key,
			});
		}
	});
	return defaultFilterAPIPayload;
};
export const selectAllOptions = (response, condition?) => {
	let result = {};
	if (response && response.data) {
		Object.keys(response.data).map((key) => {
			if (response.data[key] && response.data[key].length > 0) {
				if (condition && condition[key] && condition[key].defaultSelectAll) {
					result = {
						[key]:
							condition[key].selectionLimit && condition[key].selectionLimit > 0
								? response.data[key].filter((x, i) => i < condition[key].selectionLimit).map((x) => x.value)
								: response.data[key].map((x) => x.value),
					};
				} else {
					result = {};
				}
			}
		});
	}
	return result;
};
let chartRefData = {};
export const plotElementsOnChart = async (elements, chart, id?) => {
	const data = { ...elements };
	let Texts = [];
	const result = {};
	_.isArray(data.Text) &&
		data.Text.map((item) => {
			let ref = chart.renderer
				.label(item.text, item.x, item.y)
				.css({
					color: item.style.color,
					fontSize: item.style.fontSize,
					fontFamily: `${item.style.fontFamily} !important`,
					fontStyle: item.style.fontStyle,
					fontWeight: item.style.fontWeight,
					textDecoration: item.style.textDecoration,
				})
				.add()
				.attr({ zIndex: 5 });
			Texts.push({ item, ref });
		});
	if (_.isArray(data.Text)) {
		result["Text"] = Texts;
	}

	let Images = [];
	_.isArray(data.Image) &&
		data.Image.map((item) => {
			if (item.base64) {
				let ref = chart.renderer.image(item.base64, item.x, item.y, item.width, item.height).add().attr({ zIndex: 5 });
				Images.push({ item, ref });
			}
		});
	if (_.isArray(data.Image)) {
		result["Image"] = Images;
	}

	let Icons = [];
	_.isArray(data.Icon) &&
		(await Promise.all(
			data.Icon.map(async (item) => {
				if (item.base64) {
					if ($(`#${item.id}`)[0]) {
						return await convertSVGToBase64(item.id, item.width, item.height).then((response: any) => {
							item.base64 = response.base64;
							let ref = chart.renderer.image(response.base64, item.x, item.y, item.width, item.height).add().attr({ zIndex: 5 });
							Icons.push({ item, ref });
						});
					} else {
						let ref = chart.renderer.image(item.base64, item.x, item.y, item.width, item.height).add().attr({ zIndex: 5 });
						Icons.push({ item, ref });
						return;
					}
				}
			})
		));
	if (_.isArray(data.Icon)) {
		result["Icon"] = Icons;
	}

	let Shapes = [];
	_.isArray(data.Shape) &&
		(await Promise.all(
			data.Shape.map(async (item) => {
				if (item.base64) {
					let ref;
					if ($(`#${item.id}`)[0]) {
						await convertSVGToBase64(item.id, item.width, item.height).then((response: any) => {
							item.base64 = response.base64;
							ref = chart.renderer.image(response.base64, item.x, item.y, item.width, item.height).add().attr({ zIndex: 5 });
						});
					} else {
						ref = chart.renderer.image(item.base64, item.x, item.y, item.width, item.height).add().attr({ zIndex: 5 });
					}
					Shapes.push({ item, ref });
				}
			})
		));
	if (_.isArray(data.Shape)) {
		result["Shape"] = Shapes;
	}

	let Comments = [];
	_.isArray(data.Comment) &&
		data.Comment.map((item, i) => {
			if (item.base64) {
				let ref = chart.renderer
					.image(item.base64, item.x + 10, item.y + 10, item.width, item.height)
					.add()
					.attr({ zIndex: 5 });
				Comments.push({ item, ref });
			}
		});
	if (_.isArray(data.Comment)) {
		result["Comment"] = Comments;
	}

	if (data.chartConfig) {
		if (data.chartConfig.chartBackground) {
			chart.update({
				chart: {
					backgroundColor: data.chartConfig.chartBackground,
				},
			});
		}
		if (data.chartConfig.visualFontSize && typeof data.chartConfig.visualFontSize === "number") {
			const fontSize: number = data.chartConfig.visualFontSize;
			updateChartFonts(chart, fontSize);
		}
	}
	if (_.isArray(data.series)) {
		const seriesColors = data.series.filter((x) => x.type === "series");
		let index = 0;
		chart.series.map((series, i) => {
			if (
				series.type === "spline" ||
				series.type === "scatter" ||
				series.type === "area" ||
				series.type === "line" ||
				series.type === "bar" ||
				series.type === "bubble" ||
				(series.type === "column" && !series?.userOptions?.colorByPoint)
			) {
				const item = seriesColors[index];
				chart.series[index].update({
					color: item && item.color ? item.color : chart.series[i].color,
					lineWidth: item && item.lineWidth ? item.lineWidth : chart.series[i].options.lineWidth,
					borderColor: item && item.borderColor ? item.borderColor : chart.series[i].options.borderColor,
					borderWidth: item && item.borderWidth ? item.borderWidth : chart.series[i].borderWidth,
				});
				index++;
			} else if (series.type === " errorbar") {
				index++;
			} else {
				series.data.map((x, j) => {
					const item = seriesColors[index];
					if (item && item.color) {
						chart.series[i].data[j].update({
							color: item.color,
						});
					}
					index++;
				});
			}
		});

		let xPlotLines = [];
		chart.xAxis.map((item, i) => {
			const plotLines = _.cloneDeep(item.plotLinesAndBands);
			plotLines.map((x) => {
				x.xAxisIndex = i;
			});
			xPlotLines = [...xPlotLines, ...plotLines];
		});

		let yPlotLines = [];
		chart.yAxis.map((item, i) => {
			const plotLines = _.cloneDeep(item.plotLinesAndBands);
			plotLines.map((x) => {
				x.yAxisIndex = i;
			});
			yPlotLines = [...yPlotLines, ...plotLines];
		});

		let xIndex = 0,
			yIndex = 0;
		chart.xAxis.map((y, i) => {
			chart.xAxis[i].plotLinesAndBands.map((line) => {
				chart.xAxis[i].removePlotLine(line.id);
			});
		});
		chart.yAxis.map((y, i) => {
			chart.yAxis[i].plotLinesAndBands.map((line) => {
				chart.yAxis[i].removePlotLine(line.id);
			});
		});
		data.series.map((item) => {
			if (item.type === "xAxisPlotLine") {
				const plotLine = xPlotLines[xIndex];
				chart.xAxis[plotLine.xAxisIndex].addPlotLine({
					...plotLine.options,
					color: item.color,
				});
				xIndex++;
			} else if (item.type === "yAxisPlotLine") {
				const plotLine = yPlotLines[yIndex];
				chart.yAxis[plotLine.yAxisIndex].addPlotLine({
					...plotLine.options,
					color: item.color,
				});
				yIndex++;
			}
		});
	}

	let CustomIcons = [];
	_.isArray(data.CustomIcon) &&
		data.CustomIcon.map((item) => {
			if (item.base64) {
				let ref = chart.renderer.image(item.base64, item.x, item.y, item.width, item.height).add().attr({ zIndex: 5 });
				CustomIcons.push({ item, ref });
			}
		});
	if (_.isArray(data.CustomIcon)) {
		result["CustomIcon"] = CustomIcons;
	}

	if (id) {
		let dashbaordData = JSON.parse(localStorage.getItem("mmpw") || "{}");
		chartRefData = {
			...dashbaordData,
			...chartRefData,
			[id]: result || dashbaordData ? dashbaordData[id] : null,
		};
	}
};

export const removeElementsOnChart = (id) => {
	if (chartRefData && chartRefData[id]) {
		const chartData = chartRefData[id];
		if (_.isObject(chartData)) {
			if (chartData && chartData.Text) {
				chartData.Text.map((x) => {
					if (x.ref) {
						x.ref.destroy();
						delete x.ref;
					}
				});
			}
			if (chartData && chartData.Image) {
				chartData.Image.map((x) => {
					if (x.ref) {
						x.ref.destroy();
						delete x.ref;
					}
				});
			}
			if (chartData && chartData.Icon) {
				chartData.Icon.map((x) => {
					if (x.ref) {
						x.ref.destroy();
						delete x.ref;
					}
				});
			}
			if (chartData && chartData.Shape) {
				chartData.Shape.map((x) => {
					if (x.ref) {
						x.ref.destroy();
						delete x.ref;
					}
				});
			}
			if (chartData && chartData.Comment) {
				chartData.Comment.map((x) => {
					if (x.ref) {
						x.ref.destroy();
						delete x.ref;
					}
				});
			}
			if (chartData && chartData.CustomIcon) {
				chartData.CustomIcon.map((x) => {
					if (x.ref) {
						x.ref.destroy();
						delete x.ref;
					}
				});
			}
		}
	}
};

export const convertToInternationalCurrency = (currency, labelValue, initial?, decimal?) => {
	if (!decimal) {
		decimal = 2;
	}

	const sign = 0 > Number(labelValue) ? "-" : "";
	if (Math.abs(Number(labelValue)) >= 1.0e9) {
		const number = (Math.abs(Number(labelValue)) / 1.0e9).toFixed(decimal);
		return `${currency}${sign}${separator(number)} ${initial ? "B" : "Billion"}`;
	} else if (Math.abs(Number(labelValue)) >= 1.0e6) {
		const number = (Math.abs(Number(labelValue)) / 1.0e6).toFixed(decimal);
		return `${currency}${sign}${separator(number)} ${initial ? "M" : "Million"}`;
	} else if (Math.abs(Number(labelValue)) >= 1.0e3) {
		const number = (Math.abs(Number(labelValue)) / 1.0e3).toFixed(decimal);
		return `${currency}${sign}${separator(number)} ${initial ? "K" : "Thousand"}`;
	} else {
		const number = Math.abs(Number(labelValue)).toFixed(decimal);
		return `${currency}${sign}${separator(number)}`;
	}
};

export const convertToInternationalCurrencySingleChar = (currency, labelValue, digitAfterDecimal = 2) => {
	const sign = _.isNative(labelValue) ? "-" : "";
	if (Math.abs(Number(labelValue)) >= 1.0e9) {
		const number = (Math.abs(Number(labelValue)) / 1.0e9).toFixed(digitAfterDecimal);
		return `${currency}${sign}${separator(number)}B`;
	} else if (Math.abs(Number(labelValue)) >= 1.0e6) {
		const number = (Math.abs(Number(labelValue)) / 1.0e6).toFixed(digitAfterDecimal);
		return `${currency}${sign}${separator(number)}M`;
	} else if (Math.abs(Number(labelValue)) >= 1.0e3) {
		const number = (Math.abs(Number(labelValue)) / 1.0e3).toFixed(digitAfterDecimal);
		return `${currency}${sign}${separator(number)}T`;
	} else {
		const number = Math.abs(Number(labelValue)).toFixed(digitAfterDecimal);
		return `${currency}${sign}${separator(number)}`;
	}
};

const getbase64Image = async (url) => {
	const response = await fetch(url);
	const blob = await response.blob();
	const reader: any = new FileReader();
	await new Promise((resolve, reject) => {
		reader.onload = resolve;
		reader.onerror = reject;
		reader.readAsDataURL(blob);
	});
	return reader.result;
};

const _URL = window.URL || window.webkitURL;
export const loadImage = (data, width?, height?) => {
	return new Promise((resolve, reject) => {
		let file = data.file,
			img;
		img = new Image();
		img.onload = async (dt) => {
			resolve({
				base64: await getbase64Image(img.src),
				width: width || img.width,
				height: height || img.height,
				image: img,
			});
		};
		img.onerror = function () {
			reject();
		};
		img.src = data.file ? _URL.createObjectURL(file) : data;
	});
};

export const convertSVGToBase64 = async (id, width?, height?) => {
	let svg = $(`#${id}`)[0];
	if (!svg) {
		svg = document.getElementById(id);
	}
	svg.style.background = "none";
	const xml = new XMLSerializer().serializeToString(svg);
	const svgData = "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(xml)));
	const image = new Image();
	// later when image loads run this
	let result;
	image.src = svgData;
	await new Promise((resolve) => {
		image.onload = async () => {
			result = {
				base64: svgData,
				width: width || 20,
				height: height || 20,
				image: image,
			};
			resolve(true);
		};
	});
	return result;
};

export const loadImageFromBase64 = (data) => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = async (dt) => {
			resolve({
				base64: data.base64,
				width: data.width,
				height: data.height,
				image: img,
			});
		};
		img.onerror = function () {
			reject();
		};
		img.src = data.base64;
	});
};

export const formatNumber = (number, decimal?) => {
	return convertToInternationalCurrency("", number, "en-US", decimal);
};

export const separator = (number, decimalPoint?) => {
	if (isNaN(number)) return number;
	if (decimalPoint && !Number.isInteger(number)) {
		number = number.toFixed(decimalPoint);
	}
	const str = number.toString().split(".");
	str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return str.join(".");
};

export const getDataFormat = (periodicity) => {
	if (periodicity === "Year" || periodicity === "yearly" || periodicity === "Yearly") return "yyyy";
	else if (periodicity === "Q" || periodicity === "quarterly" || periodicity === "Quarterly") return "yyyy Qq";
	else if (periodicity === "Month" || periodicity === "monthly" || periodicity === "Monthly") return "MMM yy";
	else if (periodicity === "Week" || periodicity === "weekly" || periodicity === "Weekly") return "yyyy-MM-dd";
	else if (periodicity === "daily" || periodicity === "Daily") return "dd/MM";
};

export const stringAvatar = (name: string) => {
	name = name.trim();
	name = name.replace(/\s\s+/g, " ");
	if (!name) {
		return "";
	}
	const nameSplit = name.split(" ");
	return {
		children:
			nameSplit.length > 1
				? `${nameSplit[0][0]}${nameSplit[1][0]}`
				: nameSplit.length === 1 && nameSplit[0].length > 1
				? `${nameSplit[0][0]}${nameSplit[0][1]}`
				: nameSplit.length === 1
				? `${nameSplit[0][0]}`
				: "",
	};
};

export const getRandomColor = () => {
	const letters = "0123456789ABCDEF";
	let color = "#";
	for (let i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
};

export const checkForMultiSelections = (filters) => {
	if (filters) {
		if (_.isArray(filters.category) && filters.category.length > 1) return false;
		if (_.isArray(filters.segment) && filters.segment.length > 1) return false;
		if (_.isArray(filters.brand) && filters.brand.length > 1) return false;
		if (_.isArray(filters.subBrand) && filters.subBrand.length > 1) return false;
		if (_.isArray(filters.packSize) && filters.packSize.length > 1) return false;
		if (_.isArray(filters.anchorCategory) && filters.anchorCategory.length > 1) return false;
		if (_.isArray(filters.anchorSegment) && filters.anchorSegment.length > 1) return false;
		if (_.isArray(filters.anchorBrand) && filters.anchorBrand.length > 1) return false;
		if (_.isArray(filters.anchorSubBrand) && filters.anchorSubBrand.length > 1) return false;
		if (_.isArray(filters.anchorPackSize) && filters.anchorPackSize.length > 1) return false;

		return true;
	}
};

export const roundDecimal = (num) => {
	num = num + "e+2";
	return +(Math.round(num) + "e-2");
};

export const formatValues = (key, value, filterValues, disableFilter) => {
	if (key === "vendor") {
		filterValues.category = null;
		filterValues.segment = null;
		filterValues.brand = null;
		filterValues.subBrand = null;
		filterValues.packSize = null;

		disableFilter.category = value === null || value.length === 0;
		disableFilter.segment = true;
		disableFilter.brand = true;
		disableFilter.subBrand = true;
		disableFilter.packSize = true;
	} else if (key === "category") {
		filterValues.segment = null;
		filterValues.brand = null;
		filterValues.subBrand = null;
		filterValues.packSize = null;

		disableFilter.segment = value === null || value.length === 0;
		disableFilter.brand = true;
		disableFilter.subBrand = true;
		disableFilter.packSize = true;
	} else if (key === "segment") {
		filterValues.brand = null;
		filterValues.subBrand = null;
		filterValues.packSize = null;

		disableFilter.brand = value === null || value.length === 0;
		disableFilter.subBrand = true;
		disableFilter.packSize = true;
	} else if (key === "brand") {
		filterValues.subBrand = null;
		filterValues.packSize = null;

		disableFilter.subBrand = value === null || value.length === 0;
		disableFilter.packSize = true;
	} else if (key === "subBrand") {
		filterValues.packSize = null;

		disableFilter.packSize = value === null || value.length === 0;
	}
	return { filterValues, disableFilter };
};

export const formatAnchorValues = (key, value, filterValues, disableFilter) => {
	if (key === "anchorVendor") {
		filterValues.anchorCategory = null;
		filterValues.anchorSegment = null;
		filterValues.anchorBrand = null;
		filterValues.anchorSubBrand = null;
		filterValues.anchorPackSize = null;

		disableFilter.anchorCategory = value === null || value.length === 0;
		disableFilter.anchorSegment = true;
		disableFilter.anchorBrand = true;
		disableFilter.anchorSubBrand = true;
		disableFilter.anchorPackSize = true;
	} else if (key === "anchorCategory") {
		filterValues.anchorSegment = null;
		filterValues.anchorBrand = null;
		filterValues.anchorSubBrand = null;
		filterValues.anchorPackSize = null;

		disableFilter.anchorSegment = value === null || value.length === 0;
		disableFilter.anchorBrand = true;
		disableFilter.anchorSubBrand = true;
		disableFilter.anchorPackSize = true;
	} else if (key === "anchorSegment") {
		filterValues.anchorBrand = null;
		filterValues.anchorSubBrand = null;
		filterValues.anchorPackSize = null;

		disableFilter.anchorBrand = value === null || value.length === 0;
		disableFilter.anchorSubBrand = true;
		disableFilter.anchorPackSize = true;
	} else if (key === "anchorBrand") {
		filterValues.anchorSubBrand = null;
		filterValues.anchorPackSize = null;

		disableFilter.anchorSubBrand = value === null || value.length === 0;
		disableFilter.anchorPackSize = true;
	} else if (key === "anchorSubBrand") {
		filterValues.anchorPackSize = null;

		disableFilter.anchorPackSize = value === null || value.length === 0;
	}
	return { filterValues, disableFilter };
};

export const disabledFilters = (payload) => {
	let data = {
		category: true,
		segment: true,
		brand: true,
		subBrand: true,
		packSize: true,
	};
	const key = Object.keys(payload)[0];
	let val = payload[key];
	if (_.isArray(val) && val.length === 0) {
		val = null;
	}

	switch (key) {
		case "category":
			data = {
				...data,
				category: false,
				segment: val === null,
			};
			break;
		case "segment":
			data = {
				...data,
				category: false,
				segment: false,
				brand: val === null,
			};
			break;
		case "brand":
			data = {
				...data,
				category: false,
				segment: false,
				brand: false,
				subBrand: val === null,
			};
			break;
		case "subBrand":
			data = {
				...data,
				category: false,
				segment: false,
				brand: false,
				subBrand: false,
				packSize: val === null,
			};
			break;
		case "packSize":
			data = {
				...data,
				category: false,
				segment: false,
				brand: false,
				subBrand: false,
				packSize: false,
			};
			break;
	}
	return data;
};
export const disabledAnchorFiltersHandler = (payload) => {
	let data = {
		anchorCategory: true,
		anchorSegment: true,
		anchorBrand: true,
		anchorSubBrand: true,
		anchorPackSize: true,
	};
	const key = Object.keys(payload)[0];
	let val = payload[key];
	if (_.isArray(val) && val.length === 0) {
		val = null;
	}

	switch (key) {
		case "anchorCategory":
			data = {
				...data,
				anchorCategory: false,
				anchorSegment: val === null,
			};
			break;
		case "anchorSegment":
			data = {
				...data,
				anchorCategory: false,
				anchorSegment: false,
				anchorBrand: val === null,
			};
			break;
		case "anchorBrand":
			data = {
				...data,
				anchorCategory: false,
				anchorSegment: false,
				anchorBrand: false,
				anchorSubBrand: val === null,
			};
			break;
		case "anchorSubBrand":
			data = {
				...data,
				anchorCategory: false,
				anchorSegment: false,
				anchorBrand: false,
				anchorSubBrand: false,
				anchorPackSize: val === null,
			};
			break;
		case "anchorPackSize":
			data = {
				...data,
				anchorCategory: false,
				anchorSegment: false,
				anchorBrand: false,
				anchorSubBrand: false,
				anchorPackSize: false,
			};
			break;
	}
	return data;
};

export const FiltersWithVendor = (payload) => {
	let data = {
		vendor: true,
		category: true,
		segment: true,
		brand: true,
		subBrand: true,
		packSize: true,
	};
	const key = Object.keys(payload)[0];
	let val = payload[key];
	if (_.isArray(val) && val.length === 0) {
		val = null;
	}

	switch (key) {
		case "vendor":
			data = {
				...data,
				vendor: false,
				category: val === null,
			};
			break;
		case "category":
			data = {
				...data,
				vendor: false,
				category: false,
				segment: val === null,
			};
			break;
		case "segment":
			data = {
				...data,
				vendor: false,
				category: false,
				segment: false,
				brand: val === null,
			};
			break;
		case "brand":
			data = {
				...data,
				vendor: false,
				category: false,
				segment: false,
				brand: false,
				subBrand: val === null,
			};
			break;
		case "subBrand":
			data = {
				...data,
				vendor: false,
				category: false,
				segment: false,
				brand: false,
				subBrand: false,
				packSize: val === null,
			};
			break;
		case "packSize":
			data = {
				...data,
				vendor: false,
				category: false,
				segment: false,
				brand: false,
				subBrand: false,
				packSize: false,
			};
			break;
	}
	return data;
};

export const storeMMPWData = (mmpwData) => {
	if (_.isString(mmpwData)) {
		mmpwData = JSON.parse(mmpwData);
	}
	if (_.isObject(mmpwData)) {
		Object.keys(mmpwData).map((id) => {
			let dashbaordData = JSON.parse(localStorage.getItem("mmpw") || "{}");
			if (dashbaordData && dashbaordData[id]) {
				dashbaordData[id] = { ...dashbaordData[id], ...mmpwData[id] };
				localStorage.setItem("mmpw", JSON.stringify(dashbaordData));
			} else {
				localStorage.setItem("mmpw", JSON.stringify({ ...dashbaordData, [id]: mmpwData[id] }));
			}
		});
	}
};
export const storeMMPWFooterData = (mmpwDataFooterData) => {
	if (_.isString(mmpwDataFooterData)) {
		mmpwDataFooterData = JSON.parse(mmpwDataFooterData);
	}
	if (_.isObject(mmpwDataFooterData)) {
		Object.keys(mmpwDataFooterData).map((id) => {
			let dashbaordFooterData = JSON.parse(localStorage.getItem("mmpw-footer") || "{}");
			if (dashbaordFooterData && dashbaordFooterData[id]) {
				dashbaordFooterData[id] = [...dashbaordFooterData[id], ...mmpwDataFooterData[id]];
				localStorage.setItem("mmpw-footer", JSON.stringify(dashbaordFooterData));
			} else {
				localStorage.setItem(
					"mmpw-footer",
					JSON.stringify({
						...dashbaordFooterData,
						[id]: mmpwDataFooterData[id],
					})
				);
			}
		});
	}
};

export const travelChild = (config, key, value, selectedValues, disableFilters) => {
	if (value) {
		selectedValues[key] = value;
	}
	if (config[key].children) {
		disableFilters[config[key].children] = value === null || value.length === 0;
		selectedValues[config[key].children] = null;
		return travelChild(config, config[key].children, null, selectedValues, disableFilters);
	}
	return { selectedValues, disableFilters };
};

export const imageTagFromUrl = (url) => {
	if (!url) return null;
	const img = new Image();
	img.src = url;
	return img;
};

const getFormattedBreadcrumbString = (item, displayListCount) => {
	const remainingItems = item.length > displayListCount ? item.splice(displayListCount, item.length) : [];
	const remainingItemsString = remainingItems.length > 0 ? `and ...${remainingItems.length}more ` : "";
	return item.length > 0 ? `${item.join(", ")} ${remainingItemsString}` : "";
};

export const getBreadcrumbs = (data, keys, displayListCount = 3) => {
	let breadCrumb: any = [];
	if (_.isArray(keys) && keys.length > 0 && data && Object.keys(data).length > 0) {
		keys.map((key) => {
			const str = getFormattedBreadcrumbString([...(_.isArray(data[key]) ? data[key] : data[key] ? [data[key]] : [])], displayListCount);
			if (str) breadCrumb.push(str);
		});
	}
	return breadCrumb.join("> ");
};

export const getValueFromObject = (object, key) => {
	return key.split(".").reduce((o, k) => (k && o[k] !== undefined && o[k] !== null ? o[k] : ""), object);
};

export const blobToBase64 = (blob) => {
	return new Promise((resolve, _) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result);
		reader.readAsDataURL(blob);
	});
};

export const valueBasedOnColor = (val) => {
	if (val < 0) {
		return "#fb1111";
	} else if (val > 0) {
		return "#2dbe04";
	} else {
		return "black";
	}
};

export const removeMMPWFromLocal = (id) => {
	if (id === "All") {
		localStorage.removeItem("mmpw");
		localStorage.removeItem("mmpw-footer");
		localStorage.removeItem("periodicity");
	} else {
		let dashbaordData = JSON.parse(localStorage.getItem("mmpw") || "{}");
		if (dashbaordData && Object.keys(dashbaordData).length > 0) {
			Object.keys(dashbaordData).map((key, i) => {
				if (key.includes(id)) {
					delete dashbaordData[key];
					localStorage.setItem("mmpw", JSON.stringify(dashbaordData));
				}
			});
		}
		dashbaordData = JSON.parse(localStorage.getItem("mmpw-footer") || "{}");
		if (dashbaordData && Object.keys(dashbaordData).length > 0) {
			Object.keys(dashbaordData).map((key, i) => {
				if (key.includes(id)) {
					delete dashbaordData[key];
					localStorage.setItem("mmpw-footer", JSON.stringify(dashbaordData));
				}
			});
		}
		localStorage.removeItem("periodicity");
	}
	window.dispatchEvent(new Event("removed-storage"));
};

export const enableCompetitorFilter = (filter) => {
	return !(
		filter.country === "MEXICO" &&
		filter.geoLevel &&
		(_.isArray(filter.geoLevel)
			? filter.geoLevel.length > 0 && (filter.geoLevel[0] === "CHAIN-TTL" || filter.geoLevel[0] === "CHAIN-FORMAT")
			: filter.geoLevel === "CHAIN-TTL" || filter.geoLevel === "CHAIN-FORMAT")
	);
};

export const plotImages = async (chart, config) => {
	const payload: any = { Image: [] };
	const allProductImages = [...config.productImages];
	await Promise.all(
		allProductImages.map(async (item: any, i) => {
			if (item.base64) {
				payload.Image.push({
					type: "Image",
					base64: item.base64,
					x: chart.chartWidth - config.imgPosition.x + i * -100,
					y: config.imgPosition.y,
					width: config.imgPosition.width,
					height: config.imgDimension.height,
				});
			} else {
				return await convertSVGToBase64(item.productName, 50, 55).then((response: any) => {
					payload.Image.push({
						type: "Image",
						base64: response.base64,
						x: chart.chartWidth - config.imgPosition.x + i * -70,
						y: config.imgPosition.y,
						width: config.nameDimension.width,
						height: config.nameDimension.height,
					});
				});
			}
		})
	);
	plotElementsOnChart(payload, chart, config.id);
};

export const windowResizeListner = (chart, config) => {
	let flag = true;
	$(window).resize(function () {
		if (flag) {
			setTimeout(() => {
				flag = true;
				if (chart) {
					removeElementsOnChart(config.id);
					plotImages(chart, config);
				}
			}, 1000);
			flag = false;
		}
	});
};

export const copyScreenshotToClipboard = async (base64, dispatch) => {
	try {
		const data = await fetch(base64);
		const blob: any = await data.blob();
		const item = new ClipboardItem({ [blob.type]: blob });
		await navigator.clipboard
			.write([item])
			.then(
				function () {
					dispatch(toast("Copied to clipboard successfully!", true, 3000, "success"));
					dispatch(loader(false));
				},
				function (error) {
					dispatch(toast("Something went wrong, please try again", true, 3000, "error"));
					dispatch(loader(false));
				}
			)
			.catch(() => {
				dispatch(loader(false));
			});
	} catch (error) {
		dispatch(toast("Something went wrong, please try again", true, 3000, "error"));
		dispatch(loader(false));
	}
};

export const compileHTMLTemplate = (object, data) => {
	let html = data.series ? data.series[object.series.name] : data.html;
	Object.entries(data.variables).map(([key, value]: [string, any]) => {
		let val = getValueFromObject(object, key);
		if (value.isFormat) {
			val = formatNumber(val, value.decimal);
		}
		html = html.replace(`{{${key}}}`, val);
	});
	return html;
};

export const checkPermission = (key, user) => {
	return user && user.role ? permissions[user.role][key] : false;
};

export const sortData = (order, data, column?) => {
	if (_.isArray(data) && data.length) {
		let sortedData = [];
		switch (order) {
			case "newest": {
				const key = column || "date";
				sortedData = _.orderBy(data, key, "desc");
				break;
			}
			case "most-popular":
				sortedData = _.orderBy(data, "viewCount", "desc");
				break;
			case "a-z":
				sortedData = _.orderBy(data, [(item) => item.name.toLowerCase()]);
				break;
			case "z-a":
				sortedData = _.orderBy(data, [(item) => item.name.toLowerCase()], "desc");
				break;
		}
		return sortedData;
	}
	return [];
};

export const isValidDate = (ExpiryDate) => {
	let objDate, // date object initialized from the ExpiryDate string
		mSeconds, // ExpiryDate in milliseconds
		day, // day
		month, // month
		year; // year
	if (ExpiryDate.length !== 10) {
		return false;
	}
	if (ExpiryDate.substring(2, 3) !== "/" || ExpiryDate.substring(5, 6) !== "/") {
		return false;
	}
	month = ExpiryDate.substring(0, 2) - 1;
	day = ExpiryDate.substring(3, 5) - 0;
	year = ExpiryDate.substring(6, 10) - 0;
	if (year < 1000 || year > 3000) {
		return false;
	}
	mSeconds = new Date(year, month, day).getTime();
	objDate = new Date();
	objDate.setTime(mSeconds);

	if (objDate.getFullYear() !== year || objDate.getMonth() !== month || objDate.getDate() !== day) {
		return false;
	}
	return true;
};

// Function to order the data in brand details by date
export function sortBrandByDate(brandData: SimulationDetailsBrand): SimulationDetailsBrand {
	const monthMap: { [key: string]: number } = {
		Jan: 1,
		Feb: 2,
		Mar: 3,
		Apr: 4,
		May: 5,
		Jun: 6,
		Jul: 7,
		Aug: 8,
		Sep: 9,
		Oct: 10,
		Nov: 11,
		Dec: 12,
	};
	const quarterOrder: { [key: string]: number } = { Q1: 1, Q2: 2, Q3: 3, Q4: 4 };
	// Sort weekly data
	brandData.weekly.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

	// Sort monthly data
	brandData.monthly.sort((a, b) => {
		const [aMonth, aYear] = a.date.split(" ");
		const [bMonth, bYear] = b.date.split(" ");
		if (aYear !== bYear) {
			return parseInt(aYear) - parseInt(bYear);
		} else {
			return monthMap[aMonth] - monthMap[bMonth];
		}
	});

	// Sort quarterly data
	brandData.quarterly.sort((a, b) => {
		const quarterA = a.date.split(" ")[1];
		const quarterB = b.date.split(" ")[1];
		const yearA = parseInt(a.date.split(" ")[0]);
		const yearB = parseInt(b.date.split(" ")[0]);

		if (yearA !== yearB) {
			return yearA - yearB;
		} else {
			return quarterOrder[quarterA] - quarterOrder[quarterB];
		}
	});

	// Sort yearly data
	brandData.yearly.sort((a, b) => parseInt(a.date) - parseInt(b.date));

	return brandData;
}

/* Function to order the keys for price range graph and table
 * The keys are ordered in the following order from first to last:
 * 1. Price range with "<" symbol
 * 2. Price ranges (buckets) in ascending order
 * 3. Price range with ">" symbol
 * 4. Total
 */
export function sortPriceRangeGraphKeys(priceRangeGraphKeys: string[]) {
	return priceRangeGraphKeys.sort((a, b) => {
		if (a === "total" || a.indexOf(">") !== -1 || b.indexOf("<") !== -1) return 1;
		else if (b === "total" || b.indexOf(">") !== -1 || a.indexOf("<") !== -1) return -1;
		else {
			const a1 = a.substring(a.indexOf("$") + 1, a.indexOf("-"));
			const b1 = b.substring(b.indexOf("$") + 1, b.indexOf("-"));
			if (Number(a1) && Number(b1)) return Number(a1) - Number(b1);
			else return a.localeCompare(b);
		}
	});
}

/* Function to format numbers till provided decimal numbers
 */
export const formatDecimal = (num: number | string, decimalPlaces: number = 1): string => {
	if (typeof num == "string") {
		return parseFloat(num).toFixed(decimalPlaces);
	} else {
		return num.toFixed(decimalPlaces);
	}
};

/* Function to get distinct keys from array of object
 */
export const getDistinctKeys = (arr: Array<object>): Array<string> => {
	// Using a Set to automatically handle duplicates
	const allKeys = new Set<string>();

	arr.forEach((obj) => {
		Object.keys(obj).forEach((key) => allKeys.add(key));
	});

	// Convert Set back to Array to get distinct keys
	return Array.from(allKeys);
};

export function getTextWidth(text) {
	const font = "16px Poppins";
	// Create a temporary canvas element to measure text width
	const canvas = document.createElement("canvas");
	const context = canvas.getContext("2d");
	if (context) {
		context.font = font;
		return context.measureText(text).width;
	}
	return 0;
}

export const decryptData = (data: any) => {
	let finalData: any[] = [];
	data.forEach((item: any) => {
		const binaryString = atob(item);
		const binaryData = new Uint8Array(binaryString.split("").map((char) => char.charCodeAt(0)));
		const deCompressedData = pako.inflate(binaryData, { to: "string" });
		let jsonData = JSON.parse(deCompressedData);
		if (typeof jsonData === "string") {
			jsonData = JSON.parse(jsonData);
		}
		finalData = [...finalData, ...jsonData];
	});
	return finalData;
};

export const compressPayload = (data: string) => {
	const utf8Data = new TextEncoder().encode(data);
	const compressedData = pako.gzip(utf8Data);
	return fromByteArray(compressedData);
};

export const sortMnaPortfolioData = (data: any, level: string) => {
	const sortingOrders: Record<string, string[]> = {
		sku: ["segment", "brand", "subBrand", "packSize", "sku"],
		default: ["segment", "brand", "sku"],
	};
	const sortingKeys = sortingOrders[level.toLowerCase()] || sortingOrders.default;
	const levelIndex = sortingKeys.indexOf(level.toLowerCase());
	if (levelIndex === -1) return data;

	return data.sort((a, b) => {
		for (let i = 0; i <= levelIndex; i++) {
			const key = sortingKeys[i];
			const comparison = a[key]?.localeCompare(b[key]);
			if (comparison !== 0) return comparison;
		}
		return 0;
	});
};

/* Universal sort function to sort both numeric and string data.
 * @param {a} firstValue - The first value for comparison.
 * @param {b} secondValue - The second value for comparison.
 * @returns sort order.
 */
export const universalSort = (a, b) => {
	if (typeof a === "number" && typeof b === "number") {
		return a - b;
	}
	return String(a).localeCompare(String(b), undefined, { numeric: true });
};
