import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Box, Stack } from "@mui/system";
import { Grid } from "@mui/material";
import GeoSKUFilters from "../GeoSKUFilters/GeoSKUFilters";
import OverallFilters from "../OverallFilters/OverallFilters";
import UserInputs from "../UserInputs/UserInputs";
import dataObject from "../../../../../mocks/assortmentTool";
import { toast } from "../../../../../store/actions/toast.action";
import { dialog } from "../../../../../store/actions/dialog.action";
import { ThemeBtn } from "../../../../../styles/Common.Styled";
import { IAssortmentListPayload, IAssortmentPenetrationLimit, IMnAFilterConfigurations } from "../../../../../types/mixAndAssortment";
import { IAssortmentSelectedUserInputData } from "../../../../../types/mixAndAssortment";
import { editAssortmentDetails } from "../../../../../util/mixAndAssortmentServices";

/* Converts user input weights to percentage values.
 * @param {IAssortmentSelectedUserInputData} userWeights - The object of user input weights.
 * @returns the object of user input weights after converting to percentage.
 */
function convertUserWeightsToNumber(userWeights: IAssortmentSelectedUserInputData) {
	return {
		minimumPenetrationForSku: parseFloat((parseFloat(userWeights.minimumPenetrationForSku.toString()) * 100).toFixed(2)),
		level: userWeights.level,
		capacityConstraint: parseFloat((parseFloat(userWeights.capacityConstraint.toString()) * 100).toFixed(2)),
		inventoryTurn: parseFloat((parseFloat(userWeights.inventoryTurn.toString()) * 100).toFixed(2)),
		marketGrowth: parseFloat((parseFloat(userWeights.marketGrowth.toString()) * 100).toFixed(2)),
		marketSize: parseFloat((parseFloat(userWeights.marketSize.toString()) * 100).toFixed(2)),
		netIncrementalNopbt: parseFloat((parseFloat(userWeights.netIncrementalNopbt.toString()) * 100).toFixed(2)),
		netRevenueIncremental: parseFloat((parseFloat(userWeights.netRevenueIncremental.toString()) * 100).toFixed(2)),
		nopbt: parseFloat((parseFloat(userWeights.nopbt.toString()) * 100).toFixed(2)),
		operativeComplexity: parseFloat((parseFloat(userWeights.operativeComplexity.toString()) * 100).toFixed(2)),
		salesGrowth: parseFloat((parseFloat(userWeights.salesGrowth.toString()) * 100).toFixed(2)),
		includeWalkRate: userWeights.includeWalkRate,
		demandPotentialPercentile: parseFloat((parseFloat((userWeights.demandPotentialPercentile ?? 0).toString()) * 100).toFixed(2)),
		abilityToWinPercentile: parseFloat((parseFloat((userWeights.abilityToWinPercentile ?? 0).toString()) * 100).toFixed(2)),
	};
}

/* Converts user input weights to string values.
 * @param {IAssortmentSelectedUserInputData} userWeights - The object of user input weights.
 * @returns the object of user input weights after converting to string values.
 */
function convertUserWeightsToString(userWeights: IAssortmentSelectedUserInputData) {
	return {
		minimumPenetrationForSku: (userWeights.minimumPenetrationForSku / 100.0).toString(),
		level: userWeights.level,
		capacityConstraint: (userWeights.capacityConstraint / 100.0).toString(),
		inventoryTurn: (userWeights.inventoryTurn / 100.0).toString(),
		marketGrowth: (userWeights.marketGrowth / 100.0).toString(),
		marketSize: (userWeights.marketSize / 100.0).toString(),
		netIncrementalNopbt: (userWeights.netIncrementalNopbt / 100.0).toString(),
		netRevenueIncremental: (userWeights.netRevenueIncremental / 100.0).toString(),
		nopbt: (userWeights.nopbt / 100.0).toString(),
		operativeComplexity: (userWeights.operativeComplexity / 100.0).toString(),
		salesGrowth: (userWeights.salesGrowth / 100.0).toString(),
		includeWalkRate: userWeights.includeWalkRate,
		demandPotentialPercentile: ((userWeights.demandPotentialPercentile ?? 0) / 100.0).toString(),
		abilityToWinPercentile: ((userWeights.abilityToWinPercentile ?? 0) / 100.0).toString(),
	};
}

/* Compare user input weights to check if user input weights are changed.
 * @param {IAssortmentSelectedUserInputData} scenarioUserInputs - The object of saved user input weights for current scenario.
 * @param {IAssortmentSelectedUserInputData} updatedUserInputs - The object of updated user input weights for current scenario.
 * @returns true if user inputs are same else return false.
 */
function compareUserInputs(scenarioUserInputs: IAssortmentSelectedUserInputData, updatedUserInputs: IAssortmentSelectedUserInputData) {
	const userInputKeys = Object.keys(updatedUserInputs);
	let isUserInputChangeFlag = false;
	const convertedUserInputs = convertUserWeightsToString(updatedUserInputs);
	userInputKeys.map((keyValue: string) => {
		if ((scenarioUserInputs[keyValue] ?? 0).toString() !== (convertedUserInputs[keyValue] ?? 0).toString()) {
			isUserInputChangeFlag = true;
		}
	});
	return (
		isUserInputChangeFlag &&
		updatedUserInputs.minimumPenetrationForSku !== null &&
		updatedUserInputs.demandPotentialPercentile !== null &&
		updatedUserInputs.abilityToWinPercentile !== null
	);
}

/* Check if run simulation button needs to be disabled.
 * @param {string} name - The user description for scenario.
 * @param {IMnAFilterConfigurations} selectedFilters - The object of filter values.
 * @param {IAssortmentSelectedUserInputData} selectedUserInputs - The object of user input weights.
 * @returns true if any required value is missing else return false.
 */
function checkTotalWeight(selectedUserInputs: IAssortmentSelectedUserInputData) {
	const totalWeight =
		selectedUserInputs.netRevenueIncremental +
		selectedUserInputs.netIncrementalNopbt +
		selectedUserInputs.nopbt +
		selectedUserInputs.salesGrowth +
		selectedUserInputs.marketGrowth +
		selectedUserInputs.marketSize +
		selectedUserInputs.inventoryTurn +
		selectedUserInputs.capacityConstraint +
		selectedUserInputs.operativeComplexity;
	if (totalWeight != 100) {
		return true;
	}
	return false;
}

/* Provide a flag to disable run simulation button based on certain conditions.
 * @param {string} scenarioUserInputs - The user input values for selected scenario.
 * @param {IAssortmentSelectedUserInputData} updatedUserInputs - The object of user defined weights.
 * @param {boolean} isReadOnlyFlag - The flag to indicate whether scenario is opened in read mode or not.
 * @param {string} queryMode - The mode in which scenario is opened.
 * @returns true if any button needs to disable else return false.
 */
function disableRunSimulationBtn(scenarioUserInputs: string, updatedUserInputs: IAssortmentSelectedUserInputData, isReadOnlyFlag: boolean, queryMode: string) {
	if (isReadOnlyFlag) {
		return isReadOnlyFlag;
	} else if (
		queryMode === "new" &&
		updatedUserInputs.minimumPenetrationForSku !== null &&
		updatedUserInputs.demandPotentialPercentile !== null &&
		updatedUserInputs.abilityToWinPercentile !== null
	) {
		return false;
	} else {
		if (scenarioUserInputs) {
			const scenarioUserInput = JSON.parse(scenarioUserInputs ?? "");
			const isUserInputChangeFlag: boolean = compareUserInputs(scenarioUserInput, updatedUserInputs);
			return !isUserInputChangeFlag;
		}
	}
	return true;
}

const TopFilters: FC<{
	isNewScenario: boolean;
	selectedFilters: IMnAFilterConfigurations;
	geoFilterSkeleton: boolean;
	setShowScenarioLibrary: Dispatch<SetStateAction<boolean>>;
	setSelectedFilters: Dispatch<SetStateAction<IMnAFilterConfigurations>>;
	callBack: (payload: IAssortmentListPayload) => void;
	downloadData: () => void;
	updateActivity: (country: string) => void;
}> = ({ isNewScenario, selectedFilters, geoFilterSkeleton, setShowScenarioLibrary, setSelectedFilters, callBack, downloadData, updateActivity }) => {
	const dispatch = useDispatch();
	const queryParams = new URLSearchParams(useLocation().search);
	const queryModeParams = queryParams.get("mode") ?? "";
	const queryIsEditFlag = queryModeParams !== "read";
	const userDetail = useSelector((state: RootStateOrAny) => state.User.data);
	const scenarioDetail = useSelector((state: RootStateOrAny) => state.assortmentDetail.data);
	const isReadOnlyMode = useSelector((state: RootStateOrAny) => state.assortmentDetail.isReadOnly);
	const [userDescription, setUserDescription] = useState("");
	const [isReadOnly, setIsReadOnly] = useState(queryIsEditFlag === false);
	const [selectedUserInputs, setSelectedUserInputs] = useState<IAssortmentSelectedUserInputData>(dataObject.userInputsDefaultValues);

	const [selectedPenetrationLimit, setSelectedPenetrationLimit] = useState<IAssortmentPenetrationLimit>({
		mustHaveSKU: 0,
		mustHaveSkuCurrentAverage: 0,
		goodToHaveSKU: 0,
		goodToHaveSkuCurrentAverage: 0,
	});

	useEffect(() => {
		if (scenarioDetail) {
			const filterDetails: string[] = scenarioDetail.scenarioName.split("_");
			setSelectedFilters({
				...selectedFilters,
				country: [filterDetails[0]],
				dateTimePeriod: [filterDetails[1]],
				businessUnit: [filterDetails[2]],
				endTimePeriod: [filterDetails[3]],
			});
			setUserDescription(filterDetails[4]);
			const userInputValue = JSON.parse(scenarioDetail.userInputs);
			setSelectedUserInputs(convertUserWeightsToNumber(userInputValue));
		}
	}, [scenarioDetail]);

	useEffect(() => {
		if (isReadOnlyMode) {
			setIsReadOnly(isReadOnlyMode);
		}
	}, [isReadOnlyMode]);

	const executeRunSimulation = () => {
		updateActivity(selectedFilters.country[0]);
		if (
			!selectedFilters.country.length ||
			!selectedFilters.dateTimePeriod.length ||
			!selectedFilters.businessUnit.length ||
			!selectedFilters.endTimePeriod.length ||
			userDescription === ""
		) {
			dispatch(dialog("User description and mandatory filters can not be empty", "Missing Detail", true, true));
		} else if (checkTotalWeight(selectedUserInputs)) {
			dispatch(dialog("The sum of Assigned Weight and constraint should be equal to 100%", "Incorrect Inputs", true, true));
		} else {
			const payload = {
				scenarioName:
					selectedFilters.country[0] +
					"_" +
					selectedFilters.dateTimePeriod[0] +
					"_" +
					selectedFilters.businessUnit[0] +
					"_" +
					selectedFilters.endTimePeriod[0] +
					"_" +
					userDescription,
				isNewScenario: isNewScenario,
				createdBy: userDetail.firstName + " " + userDetail.lastName,
				userInputs: JSON.stringify(convertUserWeightsToString(selectedUserInputs)),
			};
			editAssortmentDetails(payload).then((response) => {
				if (response.data.success) {
					setShowScenarioLibrary(true);
				} else if (!response.data.success) {
					dispatch(dialog(response.data.message, "Duplicate Scenario", true, true));
				} else if (response && response.error) {
					dispatch(toast("Edit Assortment Run Simulation: " + response.error, true, 2000, "error"));
				}
			});
		}
	};

	const handleApplyFilter = (payload: IAssortmentListPayload) => {
		updateActivity(selectedFilters.country[0]);
		const scenarioUserInputs = JSON.parse(scenarioDetail.userInputs);
		const isUserInputChangeFlag: boolean = compareUserInputs(scenarioUserInputs, selectedUserInputs);
		if (isUserInputChangeFlag) {
			dispatch(dialog("You need to run simulation again to see updated results as User Inputs modified", "", false, true));
		} else {
			callBack(payload);
		}
	};

	return (
		<>
			<OverallFilters
				selectedFilters={selectedFilters}
				userDescription={userDescription}
				isFilterDisabled={!geoFilterSkeleton}
				setSelectedFilters={setSelectedFilters}
				setUserDescription={setUserDescription}
				setShowScenarioLibrary={setShowScenarioLibrary}
				updateActivity={updateActivity}
			/>
			<UserInputs selectedUserInputs={selectedUserInputs} isReadOnly={isReadOnly} callBack={setSelectedUserInputs} />
			<Box className="m-b-20">
				<Stack direction={"row"} sx={{ alignItems: "center" }}>
					<Grid container>
						<Box width="100%">
							<Grid display="flex" justifyContent="space-between">
								<ThemeBtn disabled={geoFilterSkeleton} onClick={downloadData}>
									Full Data Download
								</ThemeBtn>
								<ThemeBtn
									onClick={executeRunSimulation}
									disabled={disableRunSimulationBtn(scenarioDetail?.userInputs, selectedUserInputs, isReadOnly, queryModeParams)}
								>
									Run Simulation
								</ThemeBtn>
							</Grid>
						</Box>
					</Grid>
				</Stack>
			</Box>
			<GeoSKUFilters
				selectedFilters={selectedFilters}
				selectedPenetrationLimit={selectedPenetrationLimit}
				geoFilterSkeleton={geoFilterSkeleton}
				setSelectedFilters={setSelectedFilters}
				setSelectedPenetrationLimit={setSelectedPenetrationLimit}
				callBack={handleApplyFilter}
			/>
		</>
	);
};

export default TopFilters;
