import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Divider, Grid, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import TopFilters from "./FilterSection/TopFilters/TopFilters";
import AssortmentList from "./AssortmentList/AssortmentList";
import dataObject from "../../../mocks/assortmentTool";
import { IAssortmentListPayload, IAssortmentListTableData, IMnAFilterConfigurations } from "../../../types/mixAndAssortment";
import {
	convertToCsvWithKeys,
	downloadInCsvFormat,
	fetchFullAssortmentListData,
	fetchFullAssortmentSimulationData,
} from "../../../util/mixAndAssortmentServices";
import { toast } from "../../../store/actions/toast.action";
import { dialog } from "../../../store/actions/dialog.action";
import { getScenarioDetailData } from "../../../store/actions/MnADashboards/assortment.action";
import { decryptData } from "../../../util/helper";

const sortAssortmentSimulationData = (data: any[]) => {
	return data.sort((a, b) => {
		if (a.channel !== b.channel) return a.channel.localeCompare(b.channel);
		if (a.region !== b.region) return a.region.localeCompare(b.region);
		if (a.storeSegment !== b.storeSegment) return a.storeSegment.localeCompare(b.storeSegment);
		if (a.rank !== b.rank) return a.rank - b.rank;
		return 0;
	});
};

const AssortmentTool: FC<{ setShowScenarioLibrary: Dispatch<SetStateAction<boolean>>; isNewScenario: boolean; updateActivity: (country: string) => void }> = ({
	setShowScenarioLibrary,
	isNewScenario,
	updateActivity,
}) => {
	const useQuery = () => new URLSearchParams(useLocation().search);
	const query = useQuery();
	const queryScenario = query.get("scenario") || "";
	const queryModeParam = query.get("mode");
	const dispatch = useDispatch();
	const scenarioDetail = useSelector((state: RootStateOrAny) => state.assortmentDetail.data);
	const [selectedFilters, setSelectedFilters] = useState<IMnAFilterConfigurations>({
		country: [],
		dateTimePeriod: [],
		businessUnit: [],
		endTimePeriod: [],
		region: [],
		channel: [],
		storeSegment: [],
	});
	const [geoFilterSkeleton, setGeoFilterSkeleton] = useState(true);
	const [chartSkeleton, setChartSkeleton] = useState(true);
	const [assortmentListTableData, setAssortmentListTableData] = useState<IAssortmentListTableData[]>([]);
	useEffect(() => {
		if (queryScenario && ["edit", "read"].includes(queryModeParam ?? "")) {
			dispatch(getScenarioDetailData(queryScenario, queryModeParam !== "edit"));
		}
	}, []);
	useEffect(() => {
		if (scenarioDetail) {
			setGeoFilterSkeleton(false);
		}
	}, [scenarioDetail]);

	const applyGeoFilters = (payload: IAssortmentListPayload) => {
		fetchFullAssortmentListData(payload).then((response) => {
			if (response.data) {
				const finalData = decryptData(response.data);
				setAssortmentListTableData(finalData);
				setChartSkeleton(false);
			}
			if (response.error) {
				dispatch(toast("Download Assortment List Data: " + response.error, true, 2000, "error"));
			}
		});
	};

	const downloadOverallData = () => {
		updateActivity(scenarioDetail.scenarioName.split("_")[0]);
		fetchFullAssortmentSimulationData(scenarioDetail.scenarioName).then((response) => {
			if (response.data) {
				const finalData = decryptData(response.data);
				const sortedData = sortAssortmentSimulationData(finalData);
				const keysArray = dataObject.columnsToFullDownload.map((column) => column.field);
				if (Object.keys(sortedData).length === 0) {
					dispatch(dialog(response.message, "Download Assortment Simulation Data: ", true, true));
				} else {
					const csvData = convertToCsvWithKeys(sortedData, keysArray, dataObject.columnsToFullDownload);
					downloadInCsvFormat(csvData, "Assortment_Output_Overall_Data");
				}
			}
			if (response && response.error) {
				dispatch(toast("Download Assortment Simulation Data: " + response.error, true, 2000, "error"));
			}
		});
	};
	return (
		<Box>
			<Grid className="m-b-10" xs={12} textAlign={"right"}>
				<Typography fontSize={9}>* Mandatory</Typography>
			</Grid>
			<TopFilters
				isNewScenario={isNewScenario}
				selectedFilters={selectedFilters}
				geoFilterSkeleton={geoFilterSkeleton}
				setShowScenarioLibrary={setShowScenarioLibrary}
				setSelectedFilters={setSelectedFilters}
				callBack={applyGeoFilters}
				downloadData={downloadOverallData}
				updateActivity={updateActivity}
			/>
			<Box className="m-b-20">
				<Stack direction={"row"} sx={{ alignItems: "center" }}>
					<Grid container sx={{ alignItems: "center" }}>
						<Grid item>
							<Typography variant="h5">OUTPUT</Typography>
						</Grid>
					</Grid>
				</Stack>
			</Box>
			<Divider sx={{ borderBottomWidth: "medium" }} />
			<AssortmentList assortmentListTableData={assortmentListTableData} chartSkeleton={chartSkeleton} updateActivity={updateActivity} />
		</Box>
	);
};

export default AssortmentTool;
