import React, { Dispatch, FC, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Card } from "@mui/material";
import ScenarioLibrary from "../../../components/ScenarioLibrary";
import { loader } from "../../../store/actions/common.action";
import { saveTrendingPageData } from "../../../store/actions/YourDashboard/trendingPage.action";

const AssortmentScenario: FC<{
	setShowScenarioLibrary: Dispatch<SetStateAction<boolean>>;
	setIsNewScenario: Dispatch<SetStateAction<boolean>>;
	updateActivity: (country: string) => void;
}> = ({ setShowScenarioLibrary, setIsNewScenario, updateActivity }) => {
	const userDetail = useSelector((state: any) => state.User.data);
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const useQuery = () => new URLSearchParams(location.search);
	const query = useQuery();

	const newScenario = () => {
		query.set("mode", "new");
		history.push({ search: query.toString() });
		setIsNewScenario(true);
		setShowScenarioLibrary(false);
	};

	const viewScenario = (id: string) => {
		dispatch(saveTrendingPageData({ pageName: "Assortment Prioritizer", type: "dashboard", userId: userDetail.id }));
		updateActivity(id.split("_")[0]);
		query.set("scenario", id);
		query.set("mode", "read");
		history.push({ search: query.toString() });
		setIsNewScenario(false);
		setShowScenarioLibrary(false);
		dispatch(loader(true));
	};

	const editScenario = (id: string) => {
		dispatch(saveTrendingPageData({ pageName: "Assortment Prioritizer", type: "dashboard", userId: userDetail.id }));
		updateActivity(id.split("_")[0]);
		query.set("scenario", id);
		query.set("mode", "edit");
		history.push({ search: query.toString() });
		setIsNewScenario(false);
		setShowScenarioLibrary(false);
		dispatch(loader(true));
	};
	return (
		<Card className="m-b-20">
			<ScenarioLibrary type={"Assortment"} newScenario={newScenario} viewScenario={viewScenario} editScenario={editScenario} />
		</Card>
	);
};
export default AssortmentScenario;
