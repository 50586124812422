import React, { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, Popover, Stack, TextField } from "@mui/material";
import { DataGrid, GridColDef, GridColumnHeaderParams, GridEventListener, GridEvents } from "@mui/x-data-grid";
import { FilterAltOutlined } from "@mui/icons-material";
import { IMixTableFilters } from "../../types/mixAndAssortment";

const useStyles = makeStyles(() => ({
	dataTable: {
		border: 0,
		"& .MuiDataGrid-columnHeaders": {
			background: "transparent !important",
			borderBottom: "2px solid #DFDFDF",
		},
		"& .MuiDataGrid-columnHeaderTitleContainer": {
			justifyContent: "center",
		},
		"& .MuiDataGrid-columnHeaderTitle": {
			fontWeight: 400,
		},
		"& .MuiDataGrid-columnSeparator": {
			display: "none",
		},
		"& .MuiDataGrid-row": {
			minHeight: "fit-content !important",
			height: "72px",
			alignItems: "center",
		},
		"& .MuiDataGrid-cell": {
			border: 0,
			textAlign: "center",
			padding: "4px",
			minHeight: "fit-content !important",
		},
		"& .MuiDataGrid-cell:focus-within": {
			outline: "none",
		},
		"& .MuiDataGrid-cellContent": {
			textWrap: "wrap",
			whiteSpace: "normal",
		},
	},
	tableRow: {
		borderBottom: "1px solid #DFDFDF",
	},
}));

const filterIconClass = "header-filter-icon";

export const headerCellWithFilter = (params: GridColumnHeaderParams<any, any, any>) => (
	<Box>
		{params.colDef.headerName}
		<IconButton className={filterIconClass} size="small" aria-label="filter">
			<FilterAltOutlined className={filterIconClass} style={{ height: "20px" }} />
		</IconButton>
	</Box>
);

const DataGridWithFilters: FC<{
	columns: GridColDef<any, any, any>[];
	rows: any[];
	noRowsMessage: string;
	onCellEditCommit?: GridEventListener<GridEvents.cellEditCommit>;
}> = ({ columns, rows, noRowsMessage, onCellEditCommit }) => {
	const classes = useStyles();
	const [filterColumnList, setFilterColumnList] = useState<string[]>([]);
	const [tableFilterPopupProps, setTableFilterPopupProps] = useState({});
	const [tableFilterSearchText, setTableFilterSearchText] = useState("");
	const [tableFilters, setTableFilters] = useState<IMixTableFilters>();
	const [selectedTableFilters, setSelectedTableFilters] = useState<IMixTableFilters>();

	useEffect(() => {
		const filterColumns = columns.filter((column) => column.filterable).map((column) => column.field);
		if (filterColumns.length > 0) {
			setFilterColumnList(filterColumns);
			const tableFilterOptions: IMixTableFilters = {};
			filterColumns.forEach((column) => {
				const filterColumnOptions = new Set(rows.map((row) => row[column]));
				filterColumnOptions.delete(null);
				if (filterColumnOptions.size > 0) tableFilterOptions[column] = [...filterColumnOptions];
			});
			setTableFilters(tableFilterOptions);
			const emptyTableFilters: IMixTableFilters = {};
			filterColumns.forEach((column) => {
				emptyTableFilters[column] = [];
			});
			setSelectedTableFilters(emptyTableFilters);
		} else {
			setFilterColumnList([]);
			setTableFilters(undefined);
			setSelectedTableFilters(undefined);
		}
	}, [columns, rows]);

	const handleClose = () => {
		setTableFilterPopupProps({});
		setTableFilterSearchText("");
	};

	const handleSelection = (event: React.ChangeEvent<HTMLInputElement>, column: string, value) => {
		if (tableFilters && selectedTableFilters) {
			if (value === "selectAll") {
				if (event.target.checked) {
					setSelectedTableFilters({
						...selectedTableFilters,
						[column]: tableFilters[column].filter(
							(val: string) => val.toLowerCase().includes(tableFilterSearchText) || selectedTableFilters[column].includes(val)
						),
					});
				} else {
					setSelectedTableFilters({
						...selectedTableFilters,
						[column]: selectedTableFilters[column].filter((val: string) => !val.toLowerCase().includes(tableFilterSearchText)),
					});
				}
			} else {
				if (event.target.checked) {
					setSelectedTableFilters({
						...selectedTableFilters,
						[column]: [...selectedTableFilters[column], value],
					});
				} else {
					setSelectedTableFilters({
						...selectedTableFilters,
						[column]: selectedTableFilters[column].filter((val) => val !== value),
					});
				}
			}
		}
	};

	return tableFilters && selectedTableFilters ? (
		<>
			<DataGrid
				className={classes.dataTable}
				columns={columns}
				onColumnHeaderClick={(params, event) => {
					// Disabling default MUI sorting on clicking button, svg or path elements for filter icon
					if (
						// For IconButton
						(event.target instanceof HTMLElement && event.target.classList.contains(filterIconClass)) ||
						// For FilterAltOutlined or path inside FilterAltOutlined
						(event.target instanceof SVGElement &&
							(event.target.classList.contains(filterIconClass) || event.target.parentElement?.classList.contains(filterIconClass)))
					) {
						event.defaultMuiPrevented = true; // Disabling default MUI sorting
						setTableFilterPopupProps({ [params.field]: event.target });
					}
				}}
				rows={rows.filter((row) =>
					filterColumnList.every((column) => selectedTableFilters[column].length === 0 || selectedTableFilters[column].includes(row[column]))
				)}
				getRowClassName={(params) => classes.tableRow}
				rowHeight={72}
				onCellEditCommit={onCellEditCommit}
				components={{
					NoRowsOverlay: () => (
						<Stack height={"100%"} alignItems={"center"} justifyContent={"center"}>
							{noRowsMessage}
						</Stack>
					),
				}}
				componentsProps={{ footer: { sx: { borderTop: "2px solid #DFDFDF" } } }}
				hideFooterSelectedRowCount
				hideFooterPagination={rows.length <= 100}
				disableColumnMenu
			/>
			{filterColumnList.map((column) => (
				<Popover
					key={column}
					open={Boolean(tableFilterPopupProps[column])}
					anchorEl={tableFilterPopupProps[column]}
					onClose={handleClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
					style={{ color: "black" }}
				>
					<div
						style={{
							maxHeight: "200px",
							maxWidth: "400px",
							overflowY: "auto",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<TextField
							variant="outlined"
							placeholder="Search..."
							onChange={(event) => setTableFilterSearchText(event.target.value.trim().toLowerCase())}
							style={{ marginBottom: "8px", padding: "8px" }}
						/>

						<FormControl component="fieldset">
							<FormGroup style={{ padding: "8px" }}>
								{tableFilters && tableFilters[column] && (
									<>
										<FormControlLabel
											control={
												<Checkbox
													className={
														selectedTableFilters[column]?.filter((val: string) => val.toLowerCase().includes(tableFilterSearchText)).length ===
															tableFilters[column]?.filter((val: string) => val.toLowerCase().includes(tableFilterSearchText)).length &&
														tableFilters[column]?.filter((val: string) => val.toLowerCase().includes(tableFilterSearchText)).length > 0
															? "mui-checkbox"
															: ""
													}
													checked={
														selectedTableFilters[column]?.filter((val: string) => val.toLowerCase().includes(tableFilterSearchText)).length ===
															tableFilters[column]?.filter((val: string) => val.toLowerCase().includes(tableFilterSearchText)).length &&
														tableFilters[column]?.filter((val: string) => val.toLowerCase().includes(tableFilterSearchText)).length > 0
													}
													onChange={(event) => {
														handleSelection(event, column, "selectAll");
													}}
												/>
											}
											label={<span style={{ color: "black", marginLeft: "8px" }}>Select All</span>}
											style={{ margin: 0 }}
										/>
										{tableFilters[column]
											.filter((value: string) => value.toLowerCase().includes(tableFilterSearchText))
											.sort()
											.map((value) => (
												<FormControlLabel
													key={value}
													control={
														<Checkbox
															className={selectedTableFilters[column]?.includes(value) ? "mui-checkbox" : ""}
															checked={selectedTableFilters[column]?.includes(value)}
															onChange={(event) => {
																handleSelection(event, column, value);
															}}
															name={value}
															value={value}
														/>
													}
													label={<span style={{ color: "black", paddingLeft: "8px" }}>{value}</span>}
													style={{ margin: 0 }}
												/>
											))}
									</>
								)}
							</FormGroup>
						</FormControl>
					</div>
				</Popover>
			))}
		</>
	) : (
		<></>
	);
};

export default DataGridWithFilters;
