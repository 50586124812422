import { TUsageCountQueryFunction } from "../types/reporting";
import { formatNumber } from "../util/helper";
import {
	USAGE_COUNT_DASHBOARD_FAVORITED,
	USAGE_COUNT_DASHBOARD_SAVED,
	USAGE_COUNT_DASHBOARD_SHARED,
	USAGE_COUNT_DASHBOARD_VIEWED,
	USAGE_COUNT_TRENDING_DASHBOARD,
} from "../util/queries/reporting";

export const reportingKPIData = {
	trend: {
		// icon: faCoins,
		color: "#008cca",
		title: "Trend",
		value: "2342",
		raise: false,
		change: "0.01%",
	},
	userView: {
		// icon: faCoins,
		color: "#008cca",
		title: "Users View",
		value: "2342",
		raise: false,
		change: "0.01%",
	},
	favorited: {
		// icon: faScaleUnbalancedFlip,
		color: "#00a84d",
		title: "Favorited",
		value: "03h 45m",
		raise: true,
		change: "-4.11%",
	},
	shared: {
		//icon: faCartShopping,
		color: "#ff6910",
		title: "Shared",
		value: "27",
		raise: false,
		change: "0.01%",
	},
	saved: {
		//icon: NetRevenue,
		title: "Saved",
		value: "89",
		raise: false,
		change: "0%",
		// className: "row1NetRevenue",
	},
};

export interface IReportingChartSerie {
	type: string;
	name: string;
	fillOpacity: number;
	data: any[];
	color: string;
	tooltip: {
		pointFormatter: () => string;
	};
	categories: any[];
	yAxisLabel: string;
	queryFunction: TUsageCountQueryFunction;
	tableData: {
		show: boolean;
		headers: any[];
		values: any[];
	};
}

export const reportingChartSeries: IReportingChartSerie[] = [
	{
		type: "areaspline",
		name: "Trending Dashboard Over Time",
		fillOpacity: 0.3,
		data: [],
		color: "#f77b36",
		tooltip: {
			pointFormatter: function () {
				return formatNumber(this.y.toFixed(2));
			},
		},
		categories: [],
		yAxisLabel: "# Of Views",
		queryFunction: USAGE_COUNT_TRENDING_DASHBOARD,
		tableData: { show: true, headers: [], values: [] },
	},
	{
		type: "areaspline",
		name: "Dashboard Viewed Over Time",
		fillOpacity: 0.3,
		data: [],
		color: "#f75fef",
		tooltip: {
			pointFormatter: function () {
				return formatNumber(this.y.toFixed(2));
			},
		},
		categories: [],
		yAxisLabel: "# Of Views",
		queryFunction: USAGE_COUNT_DASHBOARD_VIEWED,
		tableData: { show: true, headers: [], values: [] },
	},
	{
		type: "areaspline",
		name: "Dashboard Saved Over Time",
		fillOpacity: 0.3,
		data: [],
		color: "#0093cd",
		tooltip: {
			pointFormatter: function () {
				return formatNumber(this.y.toFixed(2));
			},
		},
		categories: [],
		yAxisLabel: "# Of Saves",
		queryFunction: USAGE_COUNT_DASHBOARD_SAVED,
		tableData: { show: true, headers: [], values: [] },
	},
	{
		type: "areaspline",
		name: "Dashboard Shared Over Time",
		fillOpacity: 0.3,
		data: [],
		color: "#00aa52",
		tooltip: {
			pointFormatter: function () {
				return formatNumber(this.y.toFixed(2));
			},
		},
		categories: [],
		yAxisLabel: "# Of Shares",
		queryFunction: USAGE_COUNT_DASHBOARD_SHARED,
		tableData: { show: true, headers: [], values: [] },
	},
	{
		type: "areaspline",
		name: "Dashboard Favorited Over Time",
		fillOpacity: 0.3,
		data: [],
		color: "#005393",
		tooltip: {
			pointFormatter: function () {
				return formatNumber(this.y.toFixed(2));
			},
		},
		categories: [],
		yAxisLabel: "# Of Favorites",
		queryFunction: USAGE_COUNT_DASHBOARD_FAVORITED,
		tableData: { show: true, headers: [], values: [] },
	},
];
