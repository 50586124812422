import React, { FC, useEffect, useState } from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, IconButton, Popover, TextField, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { filterableColumns, sortableColumns, tableColumns } from "./TableConfig";
import ProgressBarChart from "../../../../../components/ProgressBarChart/ProgressBarChart";
import dataObject from "../../../../../mocks/assortmentTool";
import { IAssortmentListTableData, IAssortmentListTableSelectedFilters } from "../../../../../types/mixAndAssortment";
import { formatNumber, universalSort } from "../../../../../util/helper";

const useStyles = makeStyles(() => ({
	skuTable: {
		border: 0,
		"& .MuiDataGrid-columnHeaders": {
			border: 0,
			color: "#005393",
		},
		"& .MuiDataGrid-columnHeader": {
			border: "1px solid #d3d3d3",
		},
		"& .MuiDataGrid-columnHeadersInner": {
			backgroundColor: "#ECECEC",
		},
		"& .MuiDataGrid-columnHeaderTitleContainer": {
			justifyContent: "center",
		},
		"& .MuiDataGrid-columnHeaderTitle": {
			fontWeight: 600,
		},
		"& .MuiDataGrid-columnSeparator": {
			display: "none",
		},
		"& .MuiDataGrid-cell": {
			border: "1px solid #d3d3d3",
		},
	},
	selectedRow: {
		color: "#2F5597",
	},
	tableRow: {
		borderBottom: "1px solid #DFDFDF",
	},
}));

/* Calculate max values for SOP Revenue Mix and SOP NoPBT Mix.
 * @param {IAssortmentListTableData[]} assortmentListTableData - The list of assortmentListTableData object.
 * @returns max values for SOP Revenue Mix and SOP NoPBT Mix.
 */
function calculateMaxValuesSOP(assortmentListTableData: IAssortmentListTableData[]) {
	const maxSopRevenueMix: number = parseFloat(formatNumber(Math.max(...assortmentListTableData.map((obj) => obj.sopRevenueMix)), 1));
	const maxSopNopbtMix: number = parseFloat(formatNumber(Math.max(...assortmentListTableData.map((obj) => obj.sopNopbtMix)), 1));

	return {
		maxSopRevenueMix: maxSopRevenueMix ?? 0,
		maxSopNopbtMix: maxSopNopbtMix ?? 0,
	};
}

/* Update table columns for Assortment List Table.
 * @returns updated table columns for Assortment List Table.
 */
function getColumnDetails(maxValues) {
	const columns: GridColDef[] = [
		...tableColumns,
		{
			headerName: "SOP Revenue Mix (%)",
			field: "sopRevenueMix",
			width: 200,
			renderCell: (params) => (
				<Grid style={{ width: "100%" }}>{<ProgressBarChart value={params.value?.toFixed(1) ?? 0} maxValue={maxValues.maxSopRevenueMix} />}</Grid>
			),
			align: "center",
		},
		{
			headerName: "SOP NOPBT Mix (%)",
			field: "sopNopbtMix",
			width: 180,
			renderCell: (params) => (
				<Grid style={{ width: "100%" }}>{<ProgressBarChart value={params.value?.toFixed(1) ?? 0} maxValue={maxValues.maxSopNopbtMix} />}</Grid>
			),
			align: "center",
		},
	];
	return columns;
}

const AssortmentSKUTable: FC<{ assortmentListTableData: IAssortmentListTableData[] }> = ({ assortmentListTableData }) => {
	const classes = useStyles();
	const [popupElements, setPopupElements] = useState({});
	const [filterValues, setFilterValues] = useState<IAssortmentListTableSelectedFilters>({ ...dataObject.assortmentListDefaultFilters });
	const [selectedFilters, setSelectedFilters] = useState<IAssortmentListTableSelectedFilters>({ ...dataObject.assortmentListDefaultFilters });
	const [originalFilterValues, setOriginalFilterValues] = useState<IAssortmentListTableSelectedFilters>({ ...dataObject.assortmentListDefaultFilters });
	const [filteredRows, setFilteredRows] = useState<IAssortmentListTableData[]>(assortmentListTableData);
	const [pageNo, setPageNo] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [tableColumnList, setTableColumnsList] = useState<GridColDef[]>(tableColumns);
	const [filterColumnList, setFilterColumnList] = useState<string[]>([]);
	useEffect(() => {
		const maxMixValues = calculateMaxValuesSOP(assortmentListTableData);
		const columnList = getColumnDetails(maxMixValues);
		setTableColumnsList(columnList);
		const originalValues = updationOnColumnFilters(columnList, assortmentListTableData);
		setOriginalFilterValues(originalValues);
		setFilterValues(originalValues);
		setFilteredRows(assortmentListTableData);
	}, [assortmentListTableData]);

	useEffect(() => {
		let filtered = assortmentListTableData;
		let originalFilterOptions = { ...filterValues };
		const alreadyFilteredColumns: string[] = [];
		for (const column of filterColumnList) {
			alreadyFilteredColumns.push(column);
			filtered = filtered.filter((row) => {
				const selectedValues = selectedFilters[column];
				if (selectedValues.length > 0 && !selectedValues.includes(row[column])) {
					return false;
				}
				return true;
			});
			const originalValues = updationOnColumnFilters(tableColumnList, filtered);
			alreadyFilteredColumns.forEach((filterColumn) => {
				originalValues[filterColumn] = originalFilterOptions[filterColumn];
			});
			originalFilterOptions = originalValues;
		}
		if (filterColumnList.length > 0) {
			setFilterValues(originalFilterOptions);
			setFilteredRows(filtered);
		} else {
			setFilterValues(originalFilterValues);
			setFilteredRows(assortmentListTableData);
		}
	}, [selectedFilters, originalFilterValues, assortmentListTableData]);

	const updationOnColumnFilters = (tableColumns, data) => {
		const distinctValues = { ...dataObject.assortmentListDefaultFilters };
		tableColumns.forEach((column) => {
			const values = new Set();
			data.forEach((row) => {
				if (column.field === "checkoutSku") {
					const value = row[column.field] ? `${row[column.field].toFixed(1)}` : "0.0";
					values.add(value);
				} else {
					values.add(row[column.field]);
				}
			});
			distinctValues[column.field] = Array.from(values);
		});
		return distinctValues;
	};

	const handleClose = () => {
		setPopupElements({});
	};

	const handleFilterClick = (event, column) => {
		setPopupElements({
			...popupElements,
			[column.field]: event.currentTarget,
		});
		if (!selectedFilters[column.field]) {
			setSelectedFilters({
				...selectedFilters,
				[column.field]: [],
			});
		}
	};

	const handleChangeFilter = (event, column, value) => {
		const columnList = [...filterColumnList];
		const columnIndex = filterColumnList.findIndex((value: string) => value === column.field);
		if (columnIndex === -1) {
			columnList.push(column.field);
		}
		if (value === "selectAll") {
			if (!event.target.checked) {
				columnList.splice(columnIndex, 1);
			}
			setSelectedFilters({
				...selectedFilters,
				[column.field]: event.target.checked ? filterValues[column.field] : [],
				[`selectAll${column.field}`]: event.target.checked,
			});
		} else {
			const selectedFilterValues = selectedFilters[column.field].filter((val) => val !== value);
			if (!event.target.checked && selectedFilterValues.length === 0) {
				columnList.splice(columnIndex, 1);
			}
			setSelectedFilters({
				...selectedFilters,
				[column.field]: event.target.checked ? [...selectedFilters[column.field], value] : selectedFilters[column.field].filter((val) => val !== value),
				[`selectAll${column.field}`]: false,
			});
		}
		setFilterColumnList(columnList);
	};
	const handleSearch = (e, column) => {
		const { value } = e.target;

		if (value.trim() === "") {
			setFilterValues((prevFilterValues) => ({
				...prevFilterValues,
				[column.field]: originalFilterValues[column.field].sort(),
			}));
		} else {
			const filteredOptions = originalFilterValues[column.field].filter((option) => option.toString().toLowerCase().includes(value.toString().toLowerCase()));
			setFilterValues((prevFilterValues) => ({
				...prevFilterValues,
				[column.field]: filteredOptions.sort(),
			}));
		}
	};
	return (
		<Grid style={{ border: "2px solid #DFDFDF", borderRadius: "6px", height: "60vh" }}>
			<DataGrid
				className={classes.skuTable}
				rowHeight={50}
				rows={filteredRows}
				columns={tableColumnList.map((column) => ({
					...column,
					headerAlign: "center",
					headerClassName: "custom-column-header",
					renderHeader: () => (
						<Grid container>
							<Grid>{column.headerName}</Grid>
							{filterableColumns.includes(column.field) && (
								<IconButton
									size="small"
									aria-label="filter"
									onClick={(event) => {
										handleFilterClick(event, column);
									}}
								>
									<FilterAltOutlinedIcon style={{ height: "20px" }} />
								</IconButton>
							)}

							{sortableColumns.includes(column.field) && (
								<Grid style={{ background: " #f1f5fe" }}>
									{column.sortingOrder && column.sortingOrder[0] === "asc" && <KeyboardArrowUpIcon />}
									{column.sortingOrder && column.sortingOrder[0] === "desc" && <KeyboardArrowDownIcon />}
								</Grid>
							)}
						</Grid>
					),
					sortable: sortableColumns.includes(column.field),
				}))}
				pagination={true}
				pageSize={pageSize}
				disableColumnMenu={true}
				onPageChange={(no) => {
					setPageNo(no + 1);
				}}
				onPageSizeChange={(size: number) => {
					setPageSize(size);
				}}
				rowsPerPageOptions={[5, 10, 20, 50]}
				page={pageNo - 1}
				getRowId={(row) => row.id}
			/>
			{tableColumnList.map((column) => (
				<Popover
					key={column.field}
					open={Boolean(popupElements[column.field])}
					anchorEl={popupElements[column.field]}
					onClose={handleClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
					style={{ color: "black" }}
				>
					<div
						style={{
							maxHeight: "200px",
							maxWidth: "400px",
							overflowY: "auto",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<TextField
							variant="outlined"
							placeholder="Search..."
							onChange={(e) => {
								handleSearch(e, column);
							}}
							style={{ marginBottom: "8px", padding: "8px" }}
						/>

						<FormControl component="fieldset">
							<FormGroup style={{ padding: "8px" }}>
								{filterValues && filterValues[column.field] && (
									<>
										<FormControlLabel
											control={
												<Checkbox
													className={
														(selectedFilters[`selectAll${column.field}`] ||
															(selectedFilters[column.field]?.length === filterValues[column.field]?.length && filterValues[column.field]?.length > 0)) &&
														"mui-checkbox"
													}
													checked={
														selectedFilters[`selectAll${column.field}`] ||
														(selectedFilters[column.field]?.length === filterValues[column.field]?.length && filterValues[column.field]?.length > 0)
													}
													onChange={(event) => {
														handleChangeFilter(event, column, "selectAll");
													}}
													name={`selectAll${column.field}`}
												/>
											}
											label={<span style={{ color: "black", marginLeft: "8px" }}>Select All</span>}
											style={{ margin: 0 }}
										/>
										{filterValues[column.field].sort(universalSort).map((value) => (
											<FormControlLabel
												key={value}
												control={
													<Checkbox
														className={selectedFilters[column.field]?.includes(value) && "mui-checkbox"}
														checked={selectedFilters[column.field]?.includes(value)}
														onChange={(event) => {
															handleChangeFilter(event, column, value);
														}}
														name={value}
														value={value}
													/>
												}
												label={<span style={{ color: "black", paddingLeft: "8px" }}>{value}</span>}
												style={{ margin: 0 }}
											/>
										))}
									</>
								)}
							</FormGroup>
						</FormControl>
					</div>
				</Popover>
			))}
		</Grid>
	);
};

export default AssortmentSKUTable;
