import React, { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { Box, Card, Input, InputBase, Paper, Skeleton, Stack, styled } from "@mui/material";
import { PrimaryBtn } from "../../../../styles/Common.Styled";
import { FileDownloadOutlined, FileUploadOutlined } from "@mui/icons-material";
import CommonMnAFilters from "../../../../components/MnAFilters/CommonMnAFilters/CommonMAFilters";
import { mixConstraintFilters, mixConstraintFilterOrder, mixFieldHeaderNameMapping, mixConstraintUpdateAllObject } from "../../../../mocks/mixOptimizer";
import { IMixConstraintData, IMixSelectedFilters } from "../../../../types/mixAndAssortment";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import { GridColDef } from "@mui/x-data-grid";
import {
	checkUploadedMixConstraints,
	convertCsvToJsonWithKeys,
	convertToCsvWithKeys,
	downloadInCsvFormat,
	getMixConstraints,
	getMixConstraintsForScenario,
} from "../../../../util/mixAndAssortmentServices";
import { MixPopupContext } from "../../MixOptimizer";
import { useDispatch } from "react-redux";
import { toast } from "../../../../store/actions/toast.action";
import DataGridWithFilters, { headerCellWithFilter } from "../../../../components/DataGridWithFilters";

const mixConstraintColumns: GridColDef[] = [
	{
		field: "segment",
		headerName: mixFieldHeaderNameMapping.segment,
		flex: 2,
		sortable: true,
		filterable: true,
		align: "center",
		renderHeader: headerCellWithFilter,
		minWidth: 160,
	},
	{
		field: "brand",
		headerName: mixFieldHeaderNameMapping.brand,
		flex: 2,
		sortable: true,
		filterable: true,
		align: "center",
		renderHeader: headerCellWithFilter,
		minWidth: 120,
	},
	{
		field: "subBrand",
		headerName: mixFieldHeaderNameMapping.subBrand,
		flex: 3,
		sortable: true,
		filterable: true,
		align: "center",
		renderHeader: headerCellWithFilter,
		minWidth: 160,
	},
	{
		field: "packSize",
		headerName: mixFieldHeaderNameMapping.packSize,
		flex: 2,
		sortable: true,
		filterable: true,
		align: "center",
		renderHeader: headerCellWithFilter,
		minWidth: 160,
	},
	{
		field: "flavor",
		headerName: mixFieldHeaderNameMapping.flavor,
		flex: 2,
		sortable: true,
		filterable: true,
		align: "center",
		renderHeader: headerCellWithFilter,
		minWidth: 140,
	},
	{
		field: "sku",
		headerName: mixFieldHeaderNameMapping.sku,
		flex: 6,
		sortable: true,
		filterable: true,
		align: "center",
		renderHeader: headerCellWithFilter,
		minWidth: 360,
	},
	{
		field: "minMix",
		headerName: mixFieldHeaderNameMapping.minMix,
		flex: 2,
		sortable: false,
		filterable: false,
		align: "center",
		type: "number",
		editable: true,
		renderCell: (params) => <Paper sx={{ width: "80px", py: 1 }}>{params.value}</Paper>,
		minWidth: 120,
	},
	{
		field: "maxMix",
		headerName: mixFieldHeaderNameMapping.maxMix,
		flex: 2,
		sortable: false,
		filterable: false,
		align: "center",
		type: "number",
		editable: true,
		renderCell: (params) => <Paper sx={{ width: "80px", py: 1 }}>{params.value}</Paper>,
		minWidth: 120,
	},
];

const VisuallyHiddenInput = styled(Input)({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

const MixConstraint: FC<{
	selectedScenario: string | null;
	selectedFilters: IMixSelectedFilters;
	setMixConstraints: React.Dispatch<React.SetStateAction<IMixConstraintData[]>>;
	updateActivity: (country: string) => void;
}> = ({ selectedScenario, selectedFilters, setMixConstraints, updateActivity }) => {
	const dispatch = useDispatch();
	const setPopup = useContext(MixPopupContext).setPopup;
	const [gotMixConstraintsFromSavedScenario, setGotMixConstraintsFromSavedScenario] = useState(false);
	const [isUploadDisabled, setIsUploadDisabled] = useState(true);
	const [mixConstraintFilterMapping, setMixConstraintFilterMapping] = useState<{ [region: string]: string[] }>({});
	const [mixConstraintFilterSelection, setMixConstraintFilterSelection] = useState({ region: "", storeSegment: "" });
	const [updateAllInputs, setUpdateAllInputs] = useState({ minMix: 0.5, maxMix: 0.5 });
	const [defaultMixConstraintTableData, setDefaultMixConstraintTableData] = useState<IMixConstraintData[]>([]);
	const [mixConstraintTableData, setMixConstraintTableData] = useState<IMixConstraintData[]>([]);
	const [dataLoading, setDataLoading] = useState(false);
	const filtersSelected =
		selectedFilters.country.length > 0 &&
		selectedFilters.dateTimePeriod.length > 0 &&
		selectedFilters.businessUnit.length > 0 &&
		selectedFilters.endTimePeriod.length > 0 &&
		selectedFilters.level.length > 0 &&
		selectedFilters.channel.length > 0 &&
		selectedFilters.region.length > 0 &&
		selectedFilters.storeSegment.length > 0;

	useEffect(() => {
		if (filtersSelected) {
			setDataLoading(true);
			if (selectedScenario && !gotMixConstraintsFromSavedScenario) {
				getMixConstraintsForScenario(selectedScenario)
					.then((resp) => {
						if (resp.error) dispatch(toast("Error in fetching mix constraint data for selected scenario", true, 2000, "error"));
						else if (resp.data) {
							setDefaultMixConstraintTableData(resp.data.map((row, index) => ({ ...row, id: index })));
							setGotMixConstraintsFromSavedScenario(true);
						}
					})
					.finally(() => setDataLoading(false));
			} else {
				getMixConstraints(selectedFilters)
					.then((resp) => {
						if (resp.error) dispatch(toast("Error in fetching mix constraint data for current selection", true, 2000, "error"));
						else if (resp.data) setDefaultMixConstraintTableData(resp.data.map((row, index) => ({ ...row, id: index })));
					})
					.finally(() => setDataLoading(false));
			}
		} else if (defaultMixConstraintTableData.length > 0) {
			setDefaultMixConstraintTableData([]);
		}
	}, [
		selectedFilters.country,
		selectedFilters.dateTimePeriod,
		selectedFilters.businessUnit,
		selectedFilters.endTimePeriod,
		selectedFilters.level,
		selectedFilters.channel,
		selectedFilters.region,
		selectedFilters.storeSegment,
	]);

	useEffect(() => {
		const dataClone = structuredClone(defaultMixConstraintTableData);
		setMixConstraintTableData(dataClone);
		const regionMapping: { [region: string]: string[] } = {};
		defaultMixConstraintTableData.forEach((row) => {
			if (!regionMapping[row.region]) regionMapping[row.region] = [];
			if (!regionMapping[row.region].includes(row.storeSegment)) regionMapping[row.region].push(row.storeSegment);
		});
		Object.keys(regionMapping).forEach((region) => regionMapping[region].sort());
		setMixConstraintFilterMapping(regionMapping);
		if (Object.keys(regionMapping).length > 0)
			setMixConstraintFilterSelection({ region: Object.keys(regionMapping)[0], storeSegment: regionMapping[Object.keys(regionMapping)[0]][0] });
		else setMixConstraintFilterSelection({ region: "", storeSegment: "" });
	}, [defaultMixConstraintTableData]);

	useEffect(() => {
		setMixConstraints(mixConstraintTableData);
	}, [mixConstraintTableData]);

	useEffect(() => {
		const tableFiltersOptions = { segment: [], brand: [], subBrand: [], packSize: [], flavor: [], sku: [] };
		defaultMixConstraintTableData.forEach((row) => {
			if (row.region === mixConstraintFilterSelection.region || row.storeSegment === mixConstraintFilterSelection.storeSegment)
				Object.keys(tableFiltersOptions).forEach((key) => {
					if (!tableFiltersOptions[key].includes(row[key])) tableFiltersOptions[key].push(row[key]);
				});
		});
	}, [defaultMixConstraintTableData, mixConstraintFilterSelection]);

	const columnKeys = ["channel", "region", "storeSegment", "segment", "brand", "subBrand", "packSize", "flavor", "sku", "minMix", "maxMix"];

	const downloadMixConstraints = () => {
		updateActivity(selectedFilters.country);
		const csvData = convertToCsvWithKeys(
			defaultMixConstraintTableData,
			columnKeys,
			columnKeys.map((column) => ({ headerName: mixFieldHeaderNameMapping[column], field: column }))
		);
		downloadInCsvFormat(csvData, "Mix_Constraint_Data");
		setIsUploadDisabled(false);
	};

	const uploadMixConstraints = (file: File) => {
		const reader = new FileReader();
		reader.onload = (event) => {
			const csvData = event.target?.result as string;
			const uploadedData = convertCsvToJsonWithKeys(
				csvData,
				columnKeys.map((column) => ({ headerName: mixFieldHeaderNameMapping[column], field: column }))
			);
			checkUploadedMixConstraints(selectedScenario, selectedFilters, JSON.stringify(uploadedData)).then((resp) => {
				if (resp.error) setPopup({ show: true, title: "Error", message: "Error in uploading mix constraints" });
				else if (resp.data.success) {
					setPopup({ show: true, title: "Success", message: "Uploaded mix constraints have been applied successfully" });
					setMixConstraintTableData(uploadedData.map((row, index) => ({ ...row, id: index, minMix: Number(row.minMix), maxMix: Number(row.maxMix) })));
				} else
					setPopup({
						show: true,
						title: "Error",
						message: "It seems that fields other than Min Mix(%) and Max Mix(%) have been updated. Please do not update any other fields in the document.",
					});
			});
		};
		reader.readAsText(file);
	};

	const handleFilterChange = (key: string, value: string[]) => {
		if (key === "region") {
			let newStoreSegment = mixConstraintFilterMapping[value[0]][0];
			if (mixConstraintFilterMapping[value[0]].includes(mixConstraintFilterSelection.storeSegment)) newStoreSegment = mixConstraintFilterSelection.storeSegment;
			setMixConstraintFilterSelection({ region: value[0], storeSegment: newStoreSegment });
		} else if (key === "storeSegment") {
			setMixConstraintFilterSelection((prevState) => ({ ...prevState, storeSegment: value[0] }));
		}
	};

	const resetToDefault = () => {
		const rowsToReset = defaultMixConstraintTableData.filter(
			(row) => row.region === mixConstraintFilterSelection.region && row.storeSegment === mixConstraintFilterSelection.storeSegment
		);
		setMixConstraintTableData((prevState) =>
			prevState.map((row) => {
				const resetRow = rowsToReset.find(
					(resetRow) =>
						resetRow.channel === row.channel &&
						resetRow.region === row.region &&
						resetRow.storeSegment === row.storeSegment &&
						resetRow.segment === row.segment &&
						resetRow.brand === row.brand &&
						resetRow.subBrand === row.subBrand &&
						resetRow.packSize === row.packSize &&
						resetRow.flavor === row.flavor &&
						resetRow.sku === row.sku
				);
				if (resetRow) {
					row.minMix = resetRow.minMix;
					row.maxMix = resetRow.maxMix;
				}
				return row;
			})
		);
		setUpdateAllInputs({ minMix: 0.5, maxMix: 0.5 });
	};

	const applyToAll = () => {
		updateActivity(selectedFilters.country);
		const updatedData = [...mixConstraintTableData];
		updatedData.forEach((row) => {
			if (row.region === mixConstraintFilterSelection.region && row.storeSegment === mixConstraintFilterSelection.storeSegment) {
				row.minMix = Number((row.minMix + updateAllInputs.minMix).toFixed(2));
				row.maxMix = Number((row.maxMix + updateAllInputs.maxMix).toFixed(2));
			}
		});
		setMixConstraintTableData(updatedData);
	};

	return !dataLoading ? (
		<Box>
			<Stack direction={"row"} gap={2} alignItems={"center"}>
				<PrimaryBtn color={"primary"} disabled={!filtersSelected} onClick={downloadMixConstraints} sx={{ ml: "auto" }}>
					<FileDownloadOutlined />
					Download CSV
				</PrimaryBtn>
				{/* For button to have input, component property has to be label */}
				<PrimaryBtn color={"primary"} disabled={isUploadDisabled || !filtersSelected} component={"label"}>
					<FileUploadOutlined />
					Upload CSV
					<VisuallyHiddenInput
						type="file"
						onChange={(event) => {
							if (event.target instanceof HTMLInputElement && event.target.files) {
								updateActivity(selectedFilters.country);
								uploadMixConstraints(event.target.files[0]);
							}
						}}
						value=""
					/>
				</PrimaryBtn>
			</Stack>
			<Paper sx={{ backgroundColor: "white", mt: 1, p: 2 }}>
				<Stack direction={"row"} gap={2} justifyContent={"space-between"}>
					<Card sx={{ width: "540px", px: 2, py: 1, boxSizing: "content-box", height: "fit-content" }}>
						<CommonMnAFilters
							data={mixConstraintFilters}
							filterData={{ region: Object.keys(mixConstraintFilterMapping), storeSegment: mixConstraintFilterMapping[mixConstraintFilterSelection.region] }}
							onChange={handleFilterChange}
							showSkeleton={false}
							defaultFilters={mixConstraintFilterSelection}
							filterOrder={mixConstraintFilterOrder}
						/>
					</Card>
					<Card sx={{ px: 2, py: 1, boxSizing: "content-box" }}>
						<Stack direction={"column"} gap={2}>
							{mixConstraintUpdateAllObject.map((mixObject) => (
								<Stack direction={"row"} gap={1} alignItems={"center"} key={mixObject.key}>
									<DropdownTitle>{`Change ${mixObject.title}(%) by:`}</DropdownTitle>
									<Paper sx={{ width: "120px", px: "4px", display: "flex", border: "1px solid #E3E6EB", backgroundColor: "#FFFFFF", flexGrow: 1, ml: "auto" }}>
										<InputBase
											type={"number"}
											placeholder={`${mixObject.title}(%)`}
											onChange={(event: ChangeEvent<HTMLInputElement>) => {
												const value = Number(event.target.value);
												if (!isNaN(value)) setUpdateAllInputs((prev) => ({ ...prev, [mixObject.key]: value }));
											}}
											value={updateAllInputs[mixObject.key]}
										/>
									</Paper>
								</Stack>
							))}
							<Stack direction={"row"} gap={2} justifyContent={"space-between"}>
								<PrimaryBtn onClick={applyToAll} disabled={!filtersSelected}>
									Apply to All
								</PrimaryBtn>
								<PrimaryBtn onClick={resetToDefault} disabled={!filtersSelected}>
									Reset to Default
								</PrimaryBtn>
							</Stack>
						</Stack>
					</Card>
				</Stack>
				<Box height={"60vh"} mt={2}>
					<DataGridWithFilters
						columns={mixConstraintColumns}
						rows={mixConstraintTableData.filter(
							(row) => row.region === mixConstraintFilterSelection.region && row.storeSegment === mixConstraintFilterSelection.storeSegment
						)}
						noRowsMessage={
							filtersSelected ? "No SKUs available for current selection" : "Please select all Overall and Geo filters to set Mix Constraint values"
						}
						onCellEditCommit={(params) => {
							const value = Number(params.value);
							if (!isNaN(value)) {
								const updatedData = [...mixConstraintTableData];
								updatedData.forEach((row) => {
									if (row.id === params.id) {
										row[params.field] = Number(value.toFixed(2));
									}
								});
								setMixConstraintTableData(updatedData);
							}
						}}
					/>
				</Box>
			</Paper>
		</Box>
	) : (
		<Stack direction={"column"} gap={2}>
			<Stack direction={"row"} gap={2} alignItems={"center"}>
				<Skeleton variant={"rectangular"} height={40} width={160} sx={{ ml: "auto" }} />
				<Skeleton variant={"rectangular"} height={40} width={160} />
			</Stack>
			<Stack direction={"row"} gap={2} justifyContent={"space-between"}>
				<Skeleton variant={"rectangular"} height={120} width={600} />
				<Skeleton variant={"rectangular"} height={160} width={360} />
			</Stack>
			<Skeleton variant={"rectangular"} height={400} />
		</Stack>
	);
};

export default MixConstraint;
