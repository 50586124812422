import mmpw from "./mmpw.png";
import share from "./share.png";
import save from "./save.png";
import favorite from "./favorite.png";
import favorite_selected from "./favorite_selected.png";
import screenshot from "./screenshot.png";
import editMedia from "./Edit_Media.png";
import apiVsVolume from "./apiVsVolume.png";
import ept from "./EPT.png";
import macroEcon from "./macroEcon.png";
import pricingPotential from "./pricingPotential.png";
import profitParabola from "./profitParabola.png";
import salesAtEveryPricePoint from "./salesAtEveryPricePoint.png";
import brandLadder from "./brandLadder.png";
import packEcon from "./packEcon.png";
import priceSetting from "./priceSetting.png";
import revenueMap from "./revenueMap.png";
import priceRange from "./priceRange.png";
import priceLadder from "./priceLadder.png";
import assortmentPrioritizer from "./assortmentPrioritizer.png";
import mixOptimizer from "./mixOptimizer.png";
import executionTracker from "./executionTracker.png";

const dashboardImages: Record<string, string> = {
	apiVsVolume: apiVsVolume,
	EPT: ept,
	priceRangePiano: priceRange,
	priceLadder: priceLadder,
	revenueMap: revenueMap,
	packEcon: packEcon,
	macroEcon: macroEcon,
	pricingPotential: pricingPotential,
	brandLadder: brandLadder,
	profitParabola: profitParabola,
	salesAtEveryPricePoint: salesAtEveryPricePoint,
	priceSettingTool: priceSetting,
	assortmentPrioritizer: assortmentPrioritizer,
	mixOptimizer: mixOptimizer,
	executionTracker: executionTracker,
};

export { mmpw, share, save, favorite, favorite_selected, screenshot, editMedia, dashboardImages };
