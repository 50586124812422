import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import pako from "pako";
import _ from "lodash";
import { Box, Card, Divider, Grid, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { FileDownloadOutlined, DownloadingOutlined } from "@mui/icons-material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartContainer from "../../../components/HighchartContainer";
import { MixPopupContext } from "../MixOptimizer";
import Indicator from "../../../components/Loader";
import { toast } from "../../../store/actions/toast.action";
import TopFilters from "./TopFilters/TopFilters";
import Dropdown from "../../../components/Dropdown";
import { PrimaryBtn } from "../../../styles/Common.Styled";
import DataGridWithFilters, { headerCellWithFilter } from "../../../components/DataGridWithFilters";
import { formatNumber, sortMnaPortfolioData } from "../../../util/helper";
import { IMixPortfolioAndGeoData, IMixSelectedFilters, IMixSummaryData } from "../../../types/mixAndAssortment";
import {
	mixBubbleChartYAxisConfig,
	mixBubbleChartDataOptions,
	mixGeoLevelOptionsConfig,
	mixPortfolioLevelOptions,
	mixSelectedFiltersObject,
	mixSummaryCardObject,
	mixSummaryObject,
	mixFieldHeaderNameMapping,
} from "../../../mocks/mixOptimizer";
import {
	checkMixScenario,
	convertToCsv,
	convertToCsvWithKeys,
	downloadInCsvFormat,
	getMixPortfolioAndGeoData,
	getMixScenarioDownloadData,
	getMixSelectedFilters,
	getMixSummaryData,
} from "../../../util/mixAndAssortmentServices";
import { removeLabels } from "../../../store/actions/common.action";

function formatValue(value: number | null, formatType: "number" | "percent") {
	if (value === null) return "-";
	return formatType === "number" ? formatNumber(value) : `${(value * 100).toFixed(2)}%`;
}

const sortGeoData = (data: IMixPortfolioAndGeoData[], level: string) => {
	const sortingLevels: Record<string, string[]> = {
		region: ["channel", "region"],
		storeSegment: ["channel", "storeSegment"],
		regionStoreSegment: ["channel", "region", "storeSegment"],
	};

	const sortingKeys = sortingLevels[level];
	if (!sortingKeys) return data;

	return data.sort((a, b) => {
		for (const key of sortingKeys) {
			const comparison = a[key]?.localeCompare(b[key]);
			if (comparison !== 0) return comparison;
		}
		return 0;
	});
};

const portfolioAndGeoDataColumns: GridColDef[] = [
	{
		field: "segment",
		headerName: mixFieldHeaderNameMapping.segment,
		flex: 3,
		sortable: true,
		filterable: true,
		align: "center",
		renderHeader: headerCellWithFilter,
		minWidth: 180,
	},
	{
		field: "brand",
		headerName: mixFieldHeaderNameMapping.brand,
		flex: 4,
		sortable: true,
		filterable: true,
		align: "center",
		renderHeader: headerCellWithFilter,
		minWidth: 240,
	},
	{
		field: "subBrand",
		headerName: mixFieldHeaderNameMapping.subBrand,
		flex: 4,
		sortable: true,
		filterable: true,
		align: "center",
		renderHeader: headerCellWithFilter,
		minWidth: 240,
	},
	{
		field: "packSize",
		headerName: mixFieldHeaderNameMapping.packSize,
		flex: 3,
		sortable: true,
		filterable: true,
		align: "center",
		renderHeader: headerCellWithFilter,
		minWidth: 180,
	},
	{
		field: "sku",
		headerName: mixFieldHeaderNameMapping.sku,
		flex: 6,
		sortable: true,
		filterable: true,
		align: "center",
		renderHeader: headerCellWithFilter,
		minWidth: 360,
	},
	{
		field: "channel",
		headerName: mixFieldHeaderNameMapping.channel,
		flex: 3,
		sortable: true,
		filterable: true,
		align: "center",
		renderHeader: headerCellWithFilter,
		minWidth: 180,
	},
	{
		field: "region",
		headerName: mixFieldHeaderNameMapping.region,
		flex: 3,
		sortable: true,
		filterable: true,
		align: "center",
		renderHeader: headerCellWithFilter,
		minWidth: 180,
	},
	{
		field: "storeSegment",
		headerName: mixFieldHeaderNameMapping.storeSegment,
		flex: 4,
		sortable: true,
		filterable: true,
		align: "center",
		renderHeader: headerCellWithFilter,
		minWidth: 240,
	},
	{
		field: "currentMix",
		headerName: mixFieldHeaderNameMapping.currentMix,
		flex: 2,
		sortable: true,
		filterable: false,
		align: "center",
		valueFormatter: (params) => formatValue(params.value, "percent"),
		minWidth: 140,
	},
	{
		field: "idealMix",
		headerName: mixFieldHeaderNameMapping.idealMix,
		flex: 2,
		sortable: true,
		filterable: false,
		align: "center",
		valueFormatter: (params) => formatValue(params.value, "percent"),
		minWidth: 120,
	},
	{
		field: "mixDelta",
		headerName: mixFieldHeaderNameMapping.mixDelta,
		flex: 2,
		sortable: true,
		filterable: false,
		align: "center",
		valueFormatter: (params) => formatValue(params.value, "percent"),
		minWidth: 120,
	},
	{
		field: "currentMarketShare",
		headerName: mixFieldHeaderNameMapping.currentMarketShare,
		flex: 2,
		sortable: true,
		filterable: false,
		align: "center",
		valueFormatter: (params) => formatValue(params.value, "percent"),
		minWidth: 220,
	},
	{
		field: "idealMarketShare",
		headerName: mixFieldHeaderNameMapping.idealMarketShare,
		flex: 2,
		sortable: true,
		filterable: false,
		align: "center",
		valueFormatter: (params) => formatValue(params.value, "percent"),
		minWidth: 200,
	},
	{
		field: "netRevenuePerKg",
		headerName: mixFieldHeaderNameMapping.netRevenuePerKg,
		flex: 2,
		sortable: true,
		filterable: false,
		align: "center",
		valueFormatter: (params) => formatValue(params.value, "number"),
		minWidth: 200,
	},
	{
		field: "nopbtPerKg",
		headerName: mixFieldHeaderNameMapping.nopbtPerKg,
		flex: 2,
		sortable: true,
		filterable: false,
		align: "center",
		valueFormatter: (params) => formatValue(params.value, "number"),
		minWidth: 160,
	},
	{
		field: "revenueSop",
		headerName: mixFieldHeaderNameMapping.revenueSop,
		flex: 2,
		sortable: true,
		filterable: false,
		align: "center",
		valueFormatter: (params) => formatValue(params.value, "number"),
		minWidth: 160,
	},
	{
		field: "nopbtSop",
		headerName: mixFieldHeaderNameMapping.nopbtSop,
		flex: 2,
		sortable: true,
		filterable: false,
		align: "center",
		valueFormatter: (params) => formatValue(params.value, "number"),
		minWidth: 160,
	},
	{
		field: "shareSop",
		headerName: mixFieldHeaderNameMapping.shareSop,
		flex: 2,
		sortable: true,
		filterable: false,
		align: "center",
		valueFormatter: (params) => formatValue(params.value, "percent"),
		minWidth: 160,
	},
];

const decryptMixData = (data: any) => {
	let finalData: any[] = [];
	data.forEach((item: any) => {
		if (item.chunkMixPortfolioData || item.chunkSummaryData) {
			const binaryString = atob(item.chunkMixPortfolioData || item.chunkSummaryData);
			const binaryData = new Uint8Array(binaryString.split("").map((char) => char.charCodeAt(0)));
			const deCompressedData = pako.inflate(binaryData, { to: "string" });
			let jsonData = JSON.parse(deCompressedData);
			if (typeof jsonData === "string") {
				jsonData = JSON.parse(jsonData);
			}
			finalData = [...finalData, ...jsonData];
		}
	});
	return finalData;
};

const bubbleChartConfig: Highcharts.Options = {
	chart: { type: "bubble", height: "520px", plotBorderWidth: 1, zoomType: "xy" },
	title: { text: "" },
	plotOptions: { bubble: { zMin: 0, zMax: 1 } },
	xAxis: {
		title: { text: "Mix Delta" },
		labels: {
			formatter: function () {
				return formatValue(Number(this.value), "percent");
			},
		},
		plotLines: [{ color: "black", dashStyle: "Dot", width: 2, value: 0, zIndex: 3 }],
	},
	yAxis: {
		title: { text: "SOP" },
		labels: {
			formatter: function () {
				return formatValue(Number(this.value), "number");
			},
		},
		plotLines: [{ color: "black", dashStyle: "Dot", width: 2, value: 0, zIndex: 3 }],
	},
	tooltip: {
		useHTML: true,
		headerFormat: "<table style='border-collapse: collapse'>",
		pointFormatter: function () {
			return '<tr><th colspan="2"><h3>' + this.name + "</h3></th></tr>";
		},
		footerFormat: "</table>",
		followPointer: true,
	},
	navigation: { buttonOptions: { enabled: false } },
	credits: { enabled: false },
};

export const MixBubbleChartDraggableTooltip: FC<{ title: string; mixDelta: number; yField: string; sop: number; currentMix: number; idealMix: number }> = ({
	title,
	mixDelta,
	yField,
	sop,
	currentMix,
	idealMix,
}) => (
	<Stack direction={"column"}>
		<h3>{title}</h3>
		<span>Mix Delta: {formatValue(mixDelta, "percent")}</span>
		<span>
			{yField}: {formatValue(sop, yField === "Share SOP" ? "percent" : "number")}
		</span>
		<span>Current Mix: {formatValue(currentMix, "percent")}</span>
		<span>Ideal Mix: {formatValue(idealMix, "percent")}</span>
	</Stack>
);

const SummarySection: FC<{ summaryData: IMixSummaryData | undefined }> = ({ summaryData }) => {
	const theme = useTheme();
	return (
		<Grid container spacing={2}>
			{summaryData ? (
				mixSummaryObject.map((summaryDetails) => (
					<Grid item xs={12} sm={3} key={summaryDetails.prefix}>
						<Card>
							<Box p={2}>
								<Typography color={theme.palette.common.black} textAlign={"center"}>
									{summaryDetails.name}
								</Typography>
								{mixSummaryCardObject.map((cardDetails, index) => (
									<>
										<Stack direction={"row"} justifyContent={"space-between"} key={summaryDetails.prefix + cardDetails.suffix} p={1}>
											<Typography color={theme.palette.grey[500]} variant="subtitle2">
												{cardDetails.label}
											</Typography>
											<Typography color={theme.palette.common.black} fontWeight={600}>
												{formatValue(summaryData[summaryDetails.prefix + cardDetails.suffix], summaryDetails.format ?? cardDetails.format ?? "number")}
											</Typography>
										</Stack>
										{index < mixSummaryCardObject.length - 1 && <Divider sx={{ borderColor: theme.palette.grey[500] }} />}
									</>
								))}
							</Box>
						</Card>
					</Grid>
				))
			) : (
				<>
					{[0, 1, 2, 3].map((num) => (
						<Grid item xs={12} sm={3} key={`grid-skeleton-${num}`}>
							<Skeleton variant={"rectangular"} height={240} />
						</Grid>
					))}
				</>
			)}
		</Grid>
	);
};

const ScenarioDetails: FC<{
	selectedScenario: string | null;
	isNewScenarioMode: boolean;
	isEditMode: boolean;
	closeScenario: () => void;
	updateActivity: (country: string) => void;
}> = ({ selectedScenario, isNewScenarioMode, isEditMode, closeScenario, updateActivity }) => {
	const dispatch = useDispatch();
	const setPopup = useContext(MixPopupContext).setPopup;
	const bubbleChartId = "mix-optimizer-bubble-chart";
	const bubbleChartRef = useRef(null);
	const [selectedFilters, setSelectedFilters] = useState<IMixSelectedFilters>({ ...mixSelectedFiltersObject });
	const [disableDownloadScenarioData, setDisableDownloadScenarioData] = useState(false);
	const [summaryData, setSummaryData] = useState<IMixSummaryData>();
	const [portfolioDataLevel, setPortfolioDataLevel] = useState(mixPortfolioLevelOptions[0].value);
	const [portfolioTableColumns, setPortfolioTableColumns] = useState<GridColDef[]>([]);
	const [portfolioTableData, setPortfolioTableData] = useState<IMixPortfolioAndGeoData[]>([]);
	const [mixGeoLevelOptions, setMixGeoLevelOptions] = useState<{ label: string; value: string; columnsToHide: string[] }[]>([]);
	const [geoDataLevel, setGeoDataLevel] = useState("");
	const [geoTableColumns, setGeoTableColumns] = useState<GridColDef[]>([]);
	const [geoTableData, setGeoTableData] = useState<IMixPortfolioAndGeoData[]>([]);
	const [portfolioAndGeoTableData, setPortfolioAndGeoTableData] = useState<IMixPortfolioAndGeoData[]>([]);
	const [tableDataLoaded, setTableDataLoaded] = useState(false);
	const [bubbleChartDataOptions, setBubbleChartDataOptions] = useState<{ label: string; value: string }[]>([]);
	const [bubbleChartDataProps, setBubbleChartDataProps] = useState({ dataType: "", dataLevel: "", seriesField: "" });

	const downloadScenarioData = () => {
		if (selectedScenario) {
			updateActivity(selectedScenario.split("_")[0]);
			setDisableDownloadScenarioData(true);
			let csvData = "";
			getMixScenarioDownloadData(selectedScenario)
				.then((resp) => {
					if (resp.error) dispatch(toast("Error occured when fetching scenario data", true, 2000, "error"));
					else if (resp.data) {
						if (resp.data.success) {
							let finalData: any[] = [];
							resp.data.data.forEach((item: any) => {
								const binaryString = atob(item);
								const binaryData = new Uint8Array(binaryString.split("").map((char) => char.charCodeAt(0)));
								const deCompressedData = pako.inflate(binaryData, { to: "string" });
								let jsonData = JSON.parse(deCompressedData);
								if (typeof jsonData === "string") {
									jsonData = JSON.parse(jsonData);
								}
								finalData = [...finalData, ...jsonData];
							});
							if (_.isArray(finalData) && finalData.length > 0) csvData = convertToCsv(finalData);
							else dispatch(toast("Error occurred when downloading scenario data", true, 2000, "error"));
						} else dispatch(toast(resp.data.message, true, 2000, "error"));
					}
				})
				.finally(() => {
					if (csvData.length > 0) downloadInCsvFormat(csvData, `${selectedScenario} Data`);
					setDisableDownloadScenarioData(false);
				});
		}
	};

	const downloadTableData = (dataOf: "geo" | "portfolio") => {
		const dataLevel = dataOf === "geo" ? geoDataLevel : portfolioDataLevel;
		const downloadData = portfolioAndGeoTableData
			.filter((row) => row.dataLevel === dataLevel)
			.map((row) => ({
				...row,
				currentMix: row.currentMix * 100,
				idealMix: row.idealMix * 100,
				mixDelta: row.mixDelta * 100,
				currentMarketShare: row.currentMarketShare * 100,
				idealMarketShare: row.idealMarketShare * 100,
				shareSop: row.shareSop * 100,
			}));
		const columns = dataOf === "geo" ? geoTableColumns : portfolioTableColumns;
		const csvData = convertToCsvWithKeys(
			downloadData,
			columns.map((column) => column.field),
			columns.map((column) => ({ headerName: column.headerName ?? "", field: column.field }))
		);
		downloadInCsvFormat(csvData, `${selectedScenario} ${dataOf === "geo" ? "Geo Level" : "Portfolio"} Data`);
	};

	useEffect(() => {
		if (!isNewScenarioMode && selectedScenario) {
			checkMixScenario(selectedScenario)
				.then((resp) => {
					if (resp.error) {
						dispatch(toast("Error in fetching scenario information", true, 2000, "error"));
						closeScenario();
					} else if (resp.data) {
						if (resp.data.isInProgress) closeScenario();
						else {
							if (resp.data.isExpired && !isEditMode) {
								setPopup({
									show: true,
									title: "Historic Scenario Data",
									message: `${selectedScenario} scenario's results were generated over a week ago. We recommend you to rerun the simulation.`,
								});
							}
							getMixSelectedFilters(selectedScenario).then((resp) => {
								if (resp.error) dispatch(toast("Error in fetching selected filters for selected scenario", true, 2000, "error"));
								else if (resp.data) setSelectedFilters(resp.data);
							});
							if (!isEditMode) {
								getMixSummaryData(selectedScenario).then((resp) => {
									if (resp.error) dispatch(toast("Error in fetching summary data for selected scenario", true, 2000, "error"));
									else if (resp.data) {
										const finalData: any = decryptMixData(resp.data);
										setSummaryData(finalData[0]);
									}
								});
								getMixPortfolioAndGeoData(selectedScenario)
									.then((resp) => {
										if (resp.error) dispatch(toast("Error in fetching portfolio and geo data for selected scenario", true, 2000, "error"));
										else if (resp.data) {
											const finalData = decryptMixData(resp.data);
											setPortfolioAndGeoTableData(finalData.map((row, index) => ({ ...row, id: index })));
										}
									})
									.finally(() => setTableDataLoaded(true));
							}
						}
					}
				})
				.catch((e) => {
					dispatch(toast("Encountered error when fetching scenario information", true, 2000, "error"));
					closeScenario();
				});
		}
	}, [isNewScenarioMode, isEditMode, selectedScenario]);

	useEffect(() => {
		const sortedPortfolioData = sortMnaPortfolioData(portfolioAndGeoTableData, portfolioDataLevel);
		const columnsToHide = mixPortfolioLevelOptions.find((option) => option.value === portfolioDataLevel)?.columnsToHide ?? [];
		setPortfolioTableColumns(portfolioAndGeoDataColumns.filter((column) => !columnsToHide.includes(column.field)));
		setPortfolioTableData(sortedPortfolioData.filter((row) => row.dataLevel === portfolioDataLevel));
	}, [portfolioAndGeoTableData, portfolioDataLevel]);

	useEffect(() => {
		if (Object.keys(mixGeoLevelOptionsConfig).includes(selectedFilters.level)) {
			const mixGeoLevelOptionValues = mixGeoLevelOptionsConfig[selectedFilters.level].filter((object) => {
				return object.applicableGoal.indexOf(selectedFilters.goal) !== -1;
			});
			setMixGeoLevelOptions(mixGeoLevelOptionValues);
			setGeoDataLevel(mixGeoLevelOptionsConfig[selectedFilters.level][0].value);
			setBubbleChartDataOptions(mixBubbleChartDataOptions);
			setBubbleChartDataProps((prev) => ({ ...prev, dataType: mixBubbleChartDataOptions[0].value }));
		} else {
			setMixGeoLevelOptions([]);
			setGeoDataLevel("");
			const newMixBubbleChartDataOptions = mixBubbleChartDataOptions.filter((option) => option.value !== "geo");
			setBubbleChartDataOptions(newMixBubbleChartDataOptions);
			setBubbleChartDataProps((prev) => ({ ...prev, dataType: newMixBubbleChartDataOptions[0].value }));
		}
	}, [selectedFilters.level]);

	useEffect(() => {
		const sortedPortfolioData = sortGeoData(portfolioAndGeoTableData, geoDataLevel);
		const columnsToHide = mixGeoLevelOptions.find((option) => option.value === geoDataLevel)?.columnsToHide ?? [];
		setGeoTableColumns(portfolioAndGeoDataColumns.filter((column) => !columnsToHide.includes(column.field)));
		setGeoTableData(sortedPortfolioData.filter((row) => row.dataLevel === geoDataLevel));
	}, [portfolioAndGeoTableData, geoDataLevel]);

	useEffect(() => {
		setBubbleChartDataProps((prev) => ({
			...prev,
			dataLevel: bubbleChartDataProps.dataType === "geo" ? geoDataLevel : portfolioDataLevel,
			seriesField: bubbleChartDataProps.dataType === "geo" ? "region" : "segment",
		}));
	}, [bubbleChartDataProps.dataType, portfolioDataLevel, geoDataLevel]);

	useEffect(() => {
		if (bubbleChartRef && bubbleChartRef.current && bubbleChartRef.current.chart) {
			dispatch(removeLabels(bubbleChartId));
			const chart = bubbleChartRef.current.chart;
			const bubbleChartData = portfolioAndGeoTableData.filter((row) => row.dataLevel === bubbleChartDataProps.dataLevel);
			const bubbleChartSeriesNames = [...new Set(bubbleChartData.map((data) => data[bubbleChartDataProps.seriesField]))];
			const bubbleChartSeriesData = bubbleChartSeriesNames.map((seriesName) => ({
				name: seriesName,
				data: bubbleChartData
					.filter((data) => data[bubbleChartDataProps.seriesField] === seriesName)
					.map((data) => ({
						x: data.mixDelta,
						y: data[mixBubbleChartYAxisConfig[selectedFilters.goal]?.dataPoint ?? "revenueSop"],
						z: data.currentMix,
						name: data[bubbleChartDataProps.dataLevel],
						idealMix: data.idealMix,
					})),
			}));
			const yAxisField = mixBubbleChartYAxisConfig[selectedFilters.goal]?.label ?? "Revenue SOP";
			const yAxisFormat = selectedFilters.goal === "market-share" ? "percent" : "number";
			chart.title.update({ text: `${yAxisField} vs Mix Delta` });
			chart.yAxis?.forEach((y) =>
				y.update({
					title: { text: yAxisField },
					labels: {
						formatter: function () {
							return formatValue(this.value, yAxisFormat);
						},
					},
				})
			);
			chart.tooltip.update({
				pointFormatter: function () {
					return (
						'<tr><th colspan="2"><h3>' +
						this.name +
						"</h3></th></tr>" +
						"<tr><td>" +
						"Mix Delta: " +
						formatValue(this.x, "percent") +
						"</td></tr>" +
						"<tr><td>" +
						`${yAxisField}: ` +
						formatValue(this.y, yAxisFormat) +
						"</td></tr>" +
						"<tr><td>" +
						"Current Mix: " +
						formatValue(this.z, "percent") +
						"</td></tr>" +
						"<tr><td>" +
						"Ideal Mix: " +
						formatValue(this.idealMix, "percent") +
						"</td></tr>"
					);
				},
			});
			while (chart.series.length) {
				chart.series[0].remove(false);
			}
			bubbleChartSeriesData.forEach((series) => {
				chart.addSeries({ name: series.name, data: series.data }, false);
			});
			chart.redraw();
		}
	}, [selectedFilters, portfolioAndGeoTableData, bubbleChartDataProps.dataLevel, bubbleChartDataProps.seriesField]);

	return (
		<>
			<TopFilters
				selectedFilters={selectedFilters}
				setSelectedFilters={setSelectedFilters}
				selectedScenario={selectedScenario}
				isNewScenarioMode={isNewScenarioMode}
				isEditMode={isEditMode}
				closeScenario={closeScenario}
				updateActivity={updateActivity}
			/>
			{!(isNewScenarioMode || isEditMode) && (
				<Stack direction={"column"} gap={4}>
					{/* Summary */}
					<Box>
						<Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-end"} mb={1}>
							<Typography variant={"h5"}>Summary</Typography>
							<PrimaryBtn color={"primary"} onClick={downloadScenarioData} disabled={selectedScenario === null || disableDownloadScenarioData}>
								{disableDownloadScenarioData ? <DownloadingOutlined /> : <FileDownloadOutlined />}
								Download
							</PrimaryBtn>
						</Stack>
						<SummarySection summaryData={summaryData} />
					</Box>
					{/* Portfolio */}
					<Box>
						<Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-end"} mb={1}>
							<Typography variant={"h5"}>Portfolio</Typography>
							<Stack direction={"row"} alignItems={"center"} gap={1}>
								<Typography noWrap variant={"subtitle1"}>
									Level:
								</Typography>
								<Box width={200}>
									<Dropdown
										data={mixPortfolioLevelOptions}
										defaultOption={portfolioDataLevel}
										onChange={(data) => setPortfolioDataLevel(data[0])}
										maxWidth={200}
										search={{ enable: false }}
										sort={{ enable: false }}
									/>
								</Box>
								<PrimaryBtn
									color={"primary"}
									onClick={() => {
										updateActivity(selectedScenario!.split("_")[0]);
										downloadTableData("portfolio");
									}}
									disabled={portfolioAndGeoTableData.length === 0}
								>
									<FileDownloadOutlined />
									Download
								</PrimaryBtn>
							</Stack>
						</Stack>
						<Card sx={{ position: "relative" }}>
							<Indicator position={"absolute"} show={!tableDataLoaded} />
							<Box height={"80vh"} maxWidth={"100%"} overflow={"auto"} p={2}>
								<DataGridWithFilters columns={portfolioTableColumns} rows={portfolioTableData} noRowsMessage="No data available" />
							</Box>
						</Card>
					</Box>
					{/* Geo Level */}
					{/* Only visible if Level is "Within Channel" or "Market Share" */}
					{Object.keys(mixGeoLevelOptionsConfig).includes(selectedFilters.level) && (
						<Box>
							<Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-end"} mb={1}>
								<Typography variant={"h5"}>Geo Level</Typography>
								<Stack direction={"row"} alignItems={"center"} gap={1}>
									<Typography noWrap variant={"subtitle1"}>
										Level:
									</Typography>
									<Box width={200}>
										<Dropdown
											data={mixGeoLevelOptions}
											defaultOption={geoDataLevel}
											onChange={(data) => setGeoDataLevel(data[0])}
											maxWidth={200}
											search={{ enable: false }}
											sort={{ enable: false }}
										/>
									</Box>
									<PrimaryBtn
										color={"primary"}
										onClick={() => {
											updateActivity(selectedScenario!.split("_")[0]);
											downloadTableData("geo");
										}}
										disabled={portfolioAndGeoTableData.length === 0}
									>
										<FileDownloadOutlined />
										Download
									</PrimaryBtn>
								</Stack>
							</Stack>
							<Card sx={{ position: "relative" }}>
								<Indicator position={"absolute"} show={!tableDataLoaded} />
								<Box height={"80vh"} maxWidth={"100%"} overflow={"auto"} p={2}>
									<DataGridWithFilters columns={geoTableColumns} rows={geoTableData} noRowsMessage="No data available" />
								</Box>
							</Card>
						</Box>
					)}
					{/* Bubble Chart */}
					<Box>
						<Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-end"} mb={1}>
							<Typography variant={"h5"}>Bubble Chart</Typography>
							{bubbleChartDataOptions.length > 1 && (
								<Stack direction={"row"} alignItems={"center"} gap={1} width={280}>
									<Typography noWrap variant={"subtitle1"} flexGrow={1}>
										Choose Level:
									</Typography>
									<Dropdown
										data={bubbleChartDataOptions}
										defaultOption={bubbleChartDataProps.dataType}
										onChange={(data) => setBubbleChartDataProps((prev) => ({ ...prev, dataType: data[0] }))}
										maxWidth={160}
										search={{ enable: false }}
										sort={{ enable: false }}
									/>
								</Stack>
							)}
						</Stack>
						<Card sx={{ position: "relative" }}>
							<Indicator position={"absolute"} show={!tableDataLoaded} />
							<Box p={2}>
								<HighchartContainer chartRef={bubbleChartRef} id={bubbleChartId}>
									<HighchartsReact highcharts={Highcharts} options={bubbleChartConfig} ref={bubbleChartRef} />
								</HighchartContainer>
								<i>
									<b>NOTE</b>: To zoom in on a specific area, select it with your cursor.
								</i>
							</Box>
						</Card>
					</Box>
				</Stack>
			)}
		</>
	);
};

export default ScenarioDetails;
