import React, { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Card, CardContent, Grid, Switch, TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { makeStyles } from "@material-ui/core/styles";
import FilterAccordion from "../../../../../components/Filters/Filters";
import Dropdown from "../../../../../components/Dropdown/Dropdown";
import TextFieldNumber from "../../../../../components/TextFieldNumber/TextFieldNumber";
import dataObject from "../../../../../mocks/assortmentTool";
import { IAssortmentSelectedUserInputData } from "../../../../../types/mixAndAssortment";
import { getTextWidth } from "../../../../../util/helper";
import { CustomSwitch } from "../../../../HistoricPriceAndCrossPriceElasticity/HistoricPriceAndCrossPriceElasticity.Styled";

const useStyles = makeStyles(() => ({
	textField: {
		"& .MuiOutlinedInput-root.Mui-disabled": {
			backgroundColor: "#EBEBE4",
			borderRadius: "4px",
		},
	},
	disabledSign: {
		color: "rgba(0, 0, 0, 0.38)",
	},
}));

const UserInputs: FC<{
	selectedUserInputs: IAssortmentSelectedUserInputData;
	isReadOnly: boolean;
	callBack: Dispatch<SetStateAction<IAssortmentSelectedUserInputData>>;
}> = ({ selectedUserInputs, isReadOnly, callBack }) => {
	const classes = useStyles();
	const [totalAssignedWeights, setTotalAssignedWeights] = useState(100);
	const handleLevelChange = (value: string) => {
		callBack({ ...selectedUserInputs, level: value, ...dataObject.userInputsDefaultValuesbyLevel[value] });
	};

	useEffect(() => {
		const totalWeight =
			selectedUserInputs.netRevenueIncremental +
			selectedUserInputs.netIncrementalNopbt +
			selectedUserInputs.nopbt +
			selectedUserInputs.salesGrowth +
			selectedUserInputs.marketGrowth +
			selectedUserInputs.marketSize +
			selectedUserInputs.inventoryTurn +
			selectedUserInputs.capacityConstraint +
			selectedUserInputs.operativeComplexity;
		setTotalAssignedWeights(totalWeight);
	}, [selectedUserInputs]);

	return (
		<Card className="m-b-20">
			<CardContent>
				<FilterAccordion title="User Inputs" expandFlag={true}>
					<Grid container>
						<Grid item xs={12} className="m-b-10">
							<Typography className="f-w-5 m-b-20" color={"#7E7E7E"}>
								<span style={{ font: "normal normal 400 16px" }} dangerouslySetInnerHTML={{ __html: "Add Penetration (Distribution) Limit (in %)" }}></span>
							</Typography>
							<Grid container spacing={2} className="m-b-20">
								<Grid item sm={12} columns={4} display={"flex"} flexDirection={"row"} alignItems={"left"}>
									<Grid item className="p-b-10" sm={4} style={{ borderRight: "1px solid #08509A" }}>
										<Stack direction={"row"} alignItems={"center"} spacing={2}>
											<Grid item sm={6} textAlign={"left"}>
												<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
													Minimum Penetration (Distribution) for SKU*
												</Typography>
											</Grid>
											<Grid item sm={3}>
												<Box display="flex" alignItems="center">
													<TextFieldNumber
														defaultValue={selectedUserInputs.minimumPenetrationForSku}
														callback={(value) => {
															callBack({ ...selectedUserInputs, minimumPenetrationForSku: value });
														}}
														disabled={isReadOnly}
													/>
													{selectedUserInputs.minimumPenetrationForSku !== null && (
														<Box
															component={"span"}
															className={isReadOnly ? classes.disabledSign : ""}
															style={{ marginLeft: getTextWidth(selectedUserInputs.minimumPenetrationForSku) + 15, position: "absolute" }}
														>
															%
														</Box>
													)}
												</Box>
											</Grid>
										</Stack>
									</Grid>
									<Grid item sm={4} style={{ borderRight: "1px solid #08509A" }}>
										<Stack direction={"row"} alignItems={"center"} spacing={2} alignContent={"center"} justifyContent={"center"}>
											<Grid item sm={3} textAlign={"left"}>
												<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
													Choose Level
												</Typography>
											</Grid>
											<Grid item sm={6}>
												<Dropdown
													data={dataObject.menuOptionsLevel}
													onChange={(data) => {
														handleLevelChange(data[0]);
													}}
													defaultOption={selectedUserInputs.level}
													disabled={isReadOnly}
												/>
											</Grid>
										</Stack>
									</Grid>
									<Grid item sm={4}>
										<Stack direction={"row"} alignItems={"center"} spacing={2} alignContent={"end"} justifyContent={"end"}>
											<Grid item sm={4} textAlign={"right"}>
												<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
													Include Walk Rate
												</Typography>
											</Grid>
											<Grid item sm={1} style={{ marginRight: "16px" }}>
												<CustomSwitch
													{...{ inputProps: { "aria-label": "Size switch" } }}
													checked={selectedUserInputs.includeWalkRate === "N"}
													size="small"
													onChange={(event: ChangeEvent<HTMLInputElement>) => {
														if (event.target) {
															callBack({ ...selectedUserInputs, includeWalkRate: event.target.checked ? "N" : "Y" });
														}
													}}
													disabled={isReadOnly}
												/>
											</Grid>
											<Grid item sm={4} textAlign={"left"}>
												<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
													Don’t Include Walk Rate
												</Typography>
											</Grid>
										</Stack>
									</Grid>
								</Grid>
								<Grid item sm={12} spacing={2} columns={4}>
									<Grid
										sm={12}
										className="p-b-10"
										display={"flex"}
										flexDirection={"row"}
										marginLeft={"left"}
										justifyContent={"end"}
										style={{ borderBottom: "2px dashed #08509A" }}
									>
										<Typography color={"#000000"} fontSize={10}>
											<b>*NOTE:</b> Use Country level for Rationalization Recommendation Only.
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Typography className="f-w-5 m-b-20" color={"#7E7E7E"}>
								<span style={{ font: "normal normal 400 16px" }} dangerouslySetInnerHTML={{ __html: "Threshold for Rationalization" }}></span>
							</Typography>
							<Grid item sm={12} columns={4} display={"flex"} flexDirection={"row"} alignItems={"left"} pb={4} style={{ borderBottom: "2px dashed #08509A" }}>
								<Grid item className="p-b-10" sm={4} style={{ borderRight: "1px solid #08509A" }}>
									<Stack direction={"row"} alignItems={"center"} spacing={2}>
										<Grid item sm={6} textAlign={"left"}>
											<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
												Demand Potential Percentile
											</Typography>
										</Grid>
										<Grid item sm={3}>
											<Box display="flex" alignItems="center">
												<TextFieldNumber
													defaultValue={selectedUserInputs.demandPotentialPercentile}
													callback={(value) => {
														callBack({ ...selectedUserInputs, demandPotentialPercentile: value });
													}}
													disabled={isReadOnly}
												/>
												{selectedUserInputs.demandPotentialPercentile !== null && (
													<Box
														component={"span"}
														className={isReadOnly ? classes.disabledSign : ""}
														style={{ marginLeft: getTextWidth(selectedUserInputs.demandPotentialPercentile) + 15, position: "absolute" }}
													>
														%
													</Box>
												)}
											</Box>
										</Grid>
									</Stack>
								</Grid>
								<Grid item sm={4} pl={8}>
									<Stack direction={"row"} alignItems={"center"} spacing={2} alignContent={"center"} justifyContent={"flex-start"}>
										<Grid item sm={4} textAlign={"left"}>
											<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
												Ability to Win Percentile
											</Typography>
										</Grid>
										<Grid item sm={3}>
											<Box display="flex" alignItems="center">
												<TextFieldNumber
													defaultValue={selectedUserInputs.abilityToWinPercentile}
													callback={(value) => {
														callBack({ ...selectedUserInputs, abilityToWinPercentile: value });
													}}
													disabled={isReadOnly}
												/>
												{selectedUserInputs.abilityToWinPercentile !== null && (
													<Box
														component={"span"}
														className={isReadOnly ? classes.disabledSign : ""}
														style={{ marginLeft: getTextWidth(selectedUserInputs.abilityToWinPercentile) + 15, position: "absolute" }}
													>
														%
													</Box>
												)}
											</Box>
										</Grid>
									</Stack>
								</Grid>
							</Grid>
							<Typography className="f-w-5 m-b-20" color={"#7E7E7E"} mt={2}>
								<span style={{ font: "normal normal 400 16px" }} dangerouslySetInnerHTML={{ __html: "Assign Weights (in %)" }}></span>
							</Typography>
							<Grid container spacing={2} className="m-b-20">
								<Grid item sm={12} lg={12} columns={4} display={"flex"} flexDirection={"row"} alignItems={"left"}>
									<Grid item sm={3}>
										<Stack direction={"row"} alignItems={"center"} spacing={2}>
											<Grid item sm={6} textAlign={"left"}>
												<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
													Net Revenue Incremental*
												</Typography>
											</Grid>
											<Grid item sm={4}>
												<Box display="flex" alignItems="center">
													<TextFieldNumber
														defaultValue={selectedUserInputs.netRevenueIncremental}
														callback={(value) => {
															callBack({ ...selectedUserInputs, netRevenueIncremental: value });
														}}
														disabled={isReadOnly}
													/>
													{selectedUserInputs.netRevenueIncremental !== null && (
														<Box
															component={"span"}
															className={isReadOnly ? classes.disabledSign : ""}
															style={{ marginLeft: getTextWidth(selectedUserInputs.netRevenueIncremental) + 15, position: "absolute" }}
														>
															%
														</Box>
													)}
												</Box>
											</Grid>
										</Stack>
									</Grid>
									<Grid item sm={3}>
										<Stack direction={"row"} alignItems={"center"} spacing={2}>
											<Grid item sm={6} textAlign={"left"}>
												<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
													Net Incremental NOPBT*
												</Typography>
											</Grid>
											<Grid item sm={4}>
												<Box display="flex" alignItems="center">
													<TextFieldNumber
														defaultValue={selectedUserInputs.netIncrementalNopbt}
														callback={(value) => {
															callBack({ ...selectedUserInputs, netIncrementalNopbt: value });
														}}
														disabled={isReadOnly}
													/>
													{selectedUserInputs.netIncrementalNopbt !== null && (
														<Box
															component={"span"}
															className={isReadOnly ? classes.disabledSign : ""}
															style={{ marginLeft: getTextWidth(selectedUserInputs.netIncrementalNopbt) + 15, position: "absolute" }}
														>
															%
														</Box>
													)}
												</Box>
											</Grid>
										</Stack>
									</Grid>
									<Grid item sm={3}>
										<Stack direction={"row"} alignItems={"center"} spacing={2}>
											<Grid item sm={6} textAlign={"left"}>
												<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
													NOPBT %*
												</Typography>
											</Grid>
											<Grid item sm={4}>
												<Box display="flex" alignItems="center">
													<TextFieldNumber
														defaultValue={selectedUserInputs.nopbt}
														callback={(value) => {
															callBack({ ...selectedUserInputs, nopbt: value });
														}}
														disabled={isReadOnly}
													/>
													{selectedUserInputs.nopbt !== null && (
														<Box
															component={"span"}
															className={isReadOnly ? classes.disabledSign : ""}
															style={{ marginLeft: getTextWidth(selectedUserInputs.nopbt) + 15, position: "absolute" }}
														>
															%
														</Box>
													)}
												</Box>
											</Grid>
										</Stack>
									</Grid>
									<Grid item sm={3}>
										<Stack direction={"row"} alignItems={"center"} spacing={2}>
											<Grid item sm={6} textAlign={"left"}>
												<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
													Revenue Growth*
												</Typography>
											</Grid>
											<Grid item sm={4}>
												<Box display="flex" alignItems="center">
													<TextFieldNumber
														defaultValue={selectedUserInputs.salesGrowth}
														callback={(value) => {
															callBack({ ...selectedUserInputs, salesGrowth: value });
														}}
														disabled={isReadOnly}
													/>
													{selectedUserInputs.salesGrowth !== null && (
														<Box
															component={"span"}
															className={isReadOnly ? classes.disabledSign : ""}
															style={{ marginLeft: getTextWidth(selectedUserInputs.salesGrowth) + 15, position: "absolute" }}
														>
															%
														</Box>
													)}
												</Box>
											</Grid>
										</Stack>
									</Grid>
								</Grid>
								<Grid item sm={12} lg={12} spacing={2} columns={4} display={"flex"} flexDirection={"row"} alignItems={"left"}>
									<Grid item sm={3}>
										<Stack direction={"row"} alignItems={"center"} spacing={2}>
											<Grid item sm={6} textAlign={"left"}>
												<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
													Market Growth*
												</Typography>
											</Grid>
											<Grid item sm={4}>
												<Box display="flex" alignItems="center">
													<TextFieldNumber
														defaultValue={selectedUserInputs.marketGrowth}
														callback={(value) => {
															callBack({ ...selectedUserInputs, marketGrowth: value });
														}}
														disabled={isReadOnly}
													/>
													{selectedUserInputs.marketGrowth !== null && (
														<Box
															component={"span"}
															className={isReadOnly ? classes.disabledSign : ""}
															style={{ marginLeft: getTextWidth(selectedUserInputs.marketGrowth) + 15, position: "absolute" }}
														>
															%
														</Box>
													)}
												</Box>
											</Grid>
										</Stack>
									</Grid>
									<Grid item sm={3}>
										<Stack direction={"row"} alignItems={"center"} spacing={2}>
											<Grid item sm={6} textAlign={"left"}>
												<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
													Market Size*
												</Typography>
											</Grid>
											<Grid item sm={4}>
												<Box display="flex" alignItems="center">
													<TextFieldNumber
														defaultValue={selectedUserInputs.marketSize}
														callback={(value) => {
															callBack({ ...selectedUserInputs, marketSize: value });
														}}
														disabled={isReadOnly}
													/>
													{selectedUserInputs.marketSize !== null && (
														<Box
															component={"span"}
															className={isReadOnly ? classes.disabledSign : ""}
															style={{ marginLeft: getTextWidth(selectedUserInputs.marketSize) + 15, position: "absolute" }}
														>
															%
														</Box>
													)}
												</Box>
											</Grid>
										</Stack>
									</Grid>
									<Grid item sm={3}>
										<Stack direction={"row"} alignItems={"center"} spacing={2}>
											<Grid item sm={6} textAlign={"left"}>
												<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
													Inventory Turn*
												</Typography>
											</Grid>
											<Grid item sm={4}>
												<Box display="flex" alignItems="center">
													<TextFieldNumber
														defaultValue={selectedUserInputs.inventoryTurn}
														callback={(value) => {
															callBack({ ...selectedUserInputs, inventoryTurn: value });
														}}
														disabled={isReadOnly}
													/>
													{selectedUserInputs.inventoryTurn !== null && (
														<Box
															component={"span"}
															className={isReadOnly ? classes.disabledSign : ""}
															style={{ marginLeft: getTextWidth(selectedUserInputs.inventoryTurn) + 15, position: "absolute" }}
														>
															%
														</Box>
													)}
												</Box>
											</Grid>
										</Stack>
									</Grid>
								</Grid>
							</Grid>
							<Typography className={"f-w-5 m-b-20"} color={"#7E7E7E"}>
								<span style={{ font: "normal normal 400 16px" }} dangerouslySetInnerHTML={{ __html: "Constraints (in %)" }}></span>
							</Typography>
							<Grid container spacing={2}>
								<Grid item sm={12} spacing={2} columns={4} display={"flex"} flexDirection={"row"} alignItems={"left"}>
									<Grid item sm={3}>
										<Stack direction={"row"} alignItems={"center"} spacing={2}>
											<Grid item sm={6} textAlign={"left"}>
												<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
													Capacity Constraint*
												</Typography>
											</Grid>
											<Grid item sm={4}>
												<Box display="flex" alignItems="center">
													<TextFieldNumber
														defaultValue={selectedUserInputs.capacityConstraint}
														callback={(value) => {
															callBack({ ...selectedUserInputs, capacityConstraint: value });
														}}
														disabled={isReadOnly}
													/>
													{selectedUserInputs.capacityConstraint !== null && (
														<Box
															component={"span"}
															className={isReadOnly ? classes.disabledSign : ""}
															style={{ marginLeft: getTextWidth(selectedUserInputs.capacityConstraint) + 15, position: "absolute" }}
														>
															%
														</Box>
													)}
												</Box>
											</Grid>
										</Stack>
									</Grid>
									<Grid item sm={3}>
										<Stack direction={"row"} alignItems={"center"} spacing={2}>
											<Grid item sm={6} textAlign={"left"}>
												<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
													Operative Complexity*
												</Typography>
											</Grid>
											<Grid item sm={4}>
												<Box display="flex" alignItems="center">
													<TextFieldNumber
														defaultValue={selectedUserInputs.operativeComplexity}
														callback={(value) => {
															callBack({ ...selectedUserInputs, operativeComplexity: value });
														}}
														disabled={isReadOnly}
													/>
													{selectedUserInputs.operativeComplexity !== null && (
														<Box
															component={"span"}
															className={isReadOnly ? classes.disabledSign : ""}
															style={{ marginLeft: getTextWidth(selectedUserInputs.operativeComplexity) + 15, position: "absolute" }}
														>
															%
														</Box>
													)}
												</Box>
											</Grid>
										</Stack>
									</Grid>
								</Grid>
								<Grid item sm={12} spacing={2} columns={4} display={"flex"} flexDirection={"row"} marginLeft={"left"} justifyContent={"end"}>
									<Grid item sm={6}>
										<Stack direction={"row"} alignItems={"center"} spacing={2}>
											<Grid item sm={9} textAlign={"right"}>
												<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
													Sum of Assigned weights =
												</Typography>
											</Grid>
											<Grid item sm={2}>
												<Box display="flex" alignItems="center">
													<TextField
														type="text"
														className={classes.textField}
														InputProps={{
															inputProps: {
																min: 0,
																max: 100,
															},
														}}
														value={totalAssignedWeights}
														disabled
													/>
												</Box>
											</Grid>
										</Stack>
									</Grid>
								</Grid>
								<Grid item sm={12} spacing={2} columns={4} display={"flex"} flexDirection={"row"} marginLeft={"left"} justifyContent={"end"}>
									<Grid item>
										<Typography color={"#000000"} fontSize={10}>
											<b>*NOTE:</b> Total sum of Assigned weights and constraint should be 100%.
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</FilterAccordion>
			</CardContent>
		</Card>
	);
};

export default UserInputs;
