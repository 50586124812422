import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AssortmentScenario from "./AssortmentScenario/AssortmentScenario";
import AssortmentTool from "./AssortmentTool/AssortmentTool";
import { pageNames } from "../../mocks/common";
import { getScenarioDetailSuccess } from "../../store/actions/MnADashboards/assortment.action";
import { insertUserActivity } from "../../util/mixAndAssortmentServices";
import DashboardActions from "../../components/DashboardActions/DashboardActions";

const AssortmentPrioritizer: FC = () => {
	const dispatch = useDispatch();
	const userDetail = useSelector((state: any) => state.User.data);
	const location = useLocation();
	const useQuery = () => new URLSearchParams(location.search);
	const query = useQuery();
	const queryScenario = query.get("scenario");
	const scenarioModeParam = query.get("mode");
	const [showScenarioLibrary, setShowScenarioLibrary] = useState(queryScenario ? false : true);
	const [isNewScenario, setIsNewScenario] = useState(queryScenario ? false : true);

	const updateActivity = (country: string) => {
		insertUserActivity(country, pageNames.assortmentPrioritizer.label, userDetail.id);
	};

	useEffect(() => {
		if (!queryScenario && scenarioModeParam !== "new") {
			setShowScenarioLibrary(true);
			dispatch(getScenarioDetailSuccess(null, false));
		}
	}, [query, location, queryScenario]);

	return (
		<DashboardActions data={null} title={pageNames.assortmentPrioritizer.label} disabled={true}>
			{showScenarioLibrary ? (
				<AssortmentScenario setShowScenarioLibrary={setShowScenarioLibrary} setIsNewScenario={setIsNewScenario} updateActivity={updateActivity} />
			) : (
				<AssortmentTool setShowScenarioLibrary={setShowScenarioLibrary} isNewScenario={isNewScenario} updateActivity={updateActivity} />
			)}
		</DashboardActions>
	);
};

export default AssortmentPrioritizer;
